import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../_services';
/**
 * Injectable
 */
@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Check if the user is authorised before allowing access to protected routes
   *
   * @param route Target route
   * @param state Router state
   * @returns true if authorised or false if unauthorised
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map((isAuth) => {
        if (!isAuth) {
          this.router.navigate(['/auth/login']);
          return false;
        }
        return true;
      })
    );
  }
}
