/**
 * @ignore
 */
class ConfigItems {
  title?: string;
  root?: boolean;
  page?: string;
  icon?: string;
  translate?: string;
  bullet?: string;
  alignment?: string;
  submenu?: Array<ConfigItems>;
}

/**
 * @ignore
 */
class ConfigNode {
  self: Record<string, unknown>;
  items: Array<ConfigItems>;
}

/**
 * @ignore
 */
export class ConfigValues {
  header?: ConfigNode;
  aside: ConfigNode;
}

/**
 * Main menu configuration object
 */
const menuConfig: ConfigNode = {
  self: {},
  items: [
    {
      title: 'Dashboard',
      root: true,
      // icon: 'flaticon2-browser-2',
      page: '/dashboard',
      translate: 'Dashboard',
      bullet: 'dot',
    },
    {
      title: 'Assets',
      root: true,
      page: '/asset',
      // icon: 'fas fa-server',
    },
    {
      title: 'Findings',
      root: true,
      // icon: 'fas fa-heart-broken',
      page: '/vulnerability',
    },
    // {
    //   title: 'Projects',
    //   root: true,
    //   // icon: 'fas fa-file-invoice',
    //   page: '/project',
    // },
    {
      title: 'Reports',
      root: true,
      // icon: 'fas fa-file-invoice',
      page: '/report',
    },
  ],
};

/**
 * @ignore
 */
export class MenuConfig {
  /**
   * sets the menu values
   */
  public defaults: ConfigValues = {
    aside: menuConfig,
    header: menuConfig,
  };

  /**
   * returns the menu configs defaults object
   */
  public get configs(): ConfigValues {
    return this.defaults;
  }

  /**
   * sets the menu configs defaults object with the pass in value
   */
  public set configs(val: ConfigValues) {
    this.defaults = val;
  }
}
