import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService } from '../metronic/_base/layout';
import { NotificationService } from 'src/metronic/theme/header/notifications/notifications.service';
// language list
// auth

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * loader boolean flag
   */
  loader: boolean;
  /**
   * array of observable subscription streams
   */
  private unsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private notificationService: NotificationService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init checks whether the user is already authenticated
   * if user is, call the store to dispatch userInfoRequested else navigate the user to login
   */
  ngOnInit(): void {
    this.loader = this.layoutConfigService.getConfig('page-loader.type');
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screennnn
        this.splashScreenService.hide();
        // scroll to top on every route change
        window.scrollTo(0, 0);
        // fetch notifications on route change to check for any new unread notifications
        if (!event.urlAfterRedirects.includes('/auth')) this.getNotifications();
        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  /**
   * fetches all notification data
   */
  getNotifications(): void {
    this.notificationService.getAllNotifications().subscribe();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
