import { TutorialStepConfig } from '../types/tutorial-step-config';
import { clickElement } from './helpers';

export const notificationStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Notifications',
    text: 'Your notifications can be seen by clicking this bell button.',
    attachTo: {
      element: '#tutorial-notifications-open',
      on: 'left',
    },
    extraAction: () => {
      clickElement('tutorial-notifications-open');
    },
  },
  {
    title: 'Notifications',
    text:
      'Your latest read and unread notifcations will be listed here.<br><br>You will be able to flag important notifcations by clicking the flag next to them.<br><br>Flagged notifications can be seen by clicking the dropdown arrow to the right.',
    attachTo: {
      element: '.popover',
      on: 'left',
    },
    waitForDomUpdate: true,
  },
];
