import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { Subscription } from 'rxjs';
import { tutorialSections } from './tutorial-sections.const';
import { AttachTo } from './types/attach-to';
import stepConfigs from './step-configs/step-configs';
import { TutorialStepConfig } from './types/tutorial-step-config';

/**
 * Object type for storing progress in local storage
 */
interface LocallySavedProgress {
  [sectionTitle: string]: {
    version: string;
  };
}

/**
 *
 * Class for creating tutorial steps for ShepherdJS. Keeps all steps consistent.
 */
class TutorialStep {
  arrow = false;
  canClickTarget = false;
  title = '';
  text = '';
  modalOverlayOpeningPadding = 7;
  modalOverlayOpeningRadius = 3;
  classes = '';
  buttons: any;
  attachTo: undefined | AttachTo;
  beforeShowPromise: undefined | (() => Promise<any>);

  constructor(title: string, text: string, buttons: any, attachTo?: AttachTo, waitForDomUpdate?: boolean) {
    this.title = title;
    this.text = text;
    this.buttons = buttons;
    this.handleWaitForDomUpdate(waitForDomUpdate);
    this.handleAttachTo(attachTo);
  }

  /**
   * Check if Shepherd should wait before showing the next step. Used to wait for any DOM updates such as modals or popovers appearing. Shepherd will throw and error if tries to attach to a DOM element before it is present.
   */
  private handleWaitForDomUpdate(waitForDomUpdate: boolean): void {
    if (!waitForDomUpdate) return;

    this.beforeShowPromise = () =>
      new Promise((resolve) => {
        setTimeout(() => resolve(true), 50);
      });
  }

  /**
   * Adds attachTo property to the step. Also adds a class depending on the attach to position that increases spacing between the Shepherd popup and target element
   */
  private handleAttachTo(attachTo: AttachTo): void {
    if (!attachTo) return;

    this.attachTo = attachTo;

    switch (attachTo.on) {
      // Unable to add extra spacing to steps placed at the top due to how ShepherdJS calculates top positioning
      // case 'top':
      // case 'top-start':
      // case 'top-end':
      //   this.classes = 'shepherd-top';
      //   break;
      case 'right':
      case 'right-start':
      case 'right-end':
        this.classes = 'shepherd-right';
        break;
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        this.classes = 'shepherd-bottom';
        break;
      case 'left':
      case 'left-start':
      case 'left-end':
        this.classes = 'shepherd-left';
        break;
      default:
        this.classes = '';
        break;
    }
  }
}

/**
 * Service to handle starting, progressing, cancelling, and, completing tutorial steps using ShepherdJS Angular
 */
@Injectable({
  providedIn: 'root',
})
export class TutorialOverlayService implements OnDestroy {
  /**
   * Property used throughout the app to change functionality of certain things if true
   */
  inTutorialMode = false;
  /**
   * Array to track indexes of the tutorial section that need completing
   */
  sectionsToComplete: number[] = [];
  /**
   * Property used to unsubscribe from the router on destroy or closing tutorial mode
   */
  routerSubscription: Subscription;

  constructor(private shepherdService: ShepherdService, private router: Router) {}

  /**
   * Check if the user should be shown the BeginTutorialModeComponent modal
   * Not to be opened if the user is opening the help section
   */
  checkIfShowModal(): boolean {
    this.setSectionsToComplete();
    const basePath = this.router.url.split('/', 2)[1];
    if (basePath === 'help-section') return false;
    return !this.isTutorialComplete();
  }

  /**
   * Get the user's progress from local storage so they can continue from before. If a tutorial section is not listed in localStorage, its index will be added to the sectionsToComplete array
   */
  setSectionsToComplete(): void {
    const savedProgress: null | LocallySavedProgress = JSON.parse(localStorage.getItem('onboardTutorialProgress'));
    const sections: number[] = [];

    for (let i = 0; i < tutorialSections.length; i++) {
      if (!savedProgress) {
        sections.push(i);
        continue;
      }

      const savedProrgressSection = savedProgress[tutorialSections[i].title];
      if (!savedProrgressSection || savedProrgressSection.version !== tutorialSections[i].version) {
        sections.push(i);
      }
    }

    this.sectionsToComplete = sections;
  }

  /**
   * Returns the current tutorial section by taking the first index in sectionsToComplete. This value is the index for the tutorialSections that needs to be completed. For example: if the value is 2, it is the third tutoral section that needs completing
   */
  getCurrentSection(): { title: string; page: string; version: string } {
    return tutorialSections[this.sectionsToComplete[0]];
  }

  /**
   * Checks if there any tutorialSections indexes in the sectionsToComplete array. If not, the user has completed all sections in the past.
   */
  isTutorialComplete(): boolean {
    return !this.sectionsToComplete.length;
  }

  /**
   * Initialises tutorial mode by setting inTutorialMode as true, calling subscribeToRouteChange, and redirecting the user to tutorial-mode-loading page
   */
  initTutorialMode(): void {
    this.inTutorialMode = true;
    this.subscribeToRouteChange();
    this.shepherdService.modal = true;
    this.router
      .navigateByUrl('/tutorial-mode-loading', { state: { mode: 'starting' } })
      .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
      .then(() => this.goToPageForTutorialSection());
  }

  /**
   * Subscribes to router events. When the navigation ends and the url is the same as the page for the tutorial section, steps will be built for the next section and ShepherdJS will start with those steps.
   */
  subscribeToRouteChange(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.getCurrentSection().page === event.url) {
        this.buildStepsForSection();
        this.shepherdService.start();
      }
    });
  }

  /**
   * Redirects the user to the page where the tutorial section will be carried out on.
   */
  goToPageForTutorialSection(): void {
    this.router.navigateByUrl(this.getCurrentSection().page);
  }

  /**
   * Builds steps for ShepherdJS using the step configs for the current section. Adds steps to ShepherdJS in preperation for it to start
   */
  buildStepsForSection(): void {
    const section = this.getCurrentSection().title;
    const configs: TutorialStepConfig[] = stepConfigs[section];

    const steps: TutorialStep[] = configs.map((step) => {
      const buttons = [
        {
          action: () => {
            this.cancelTutorial();
          },
          classes: 'btn btn-outline-primary',
          text: 'Close',
        },
        {
          action: () => {
            this.shepherdService.next();
            if (step.extraAction) step.extraAction();
          },
          classes: 'btn btn-primary',
          text: 'Continue',
        },
      ];

      return new TutorialStep(step.title, step.text, buttons, step.attachTo, step.waitForDomUpdate);
    });

    steps.push(this.buildSectionFinalStep());
    this.shepherdService.defaultStepOptions = steps;
    this.shepherdService.addSteps(steps);
  }

  /**
   * Builds the final step for the section with buttons that handle increasing the progress. Also details information of completed section and states the next section title to the user
   */
  buildSectionFinalStep(): TutorialStep {
    const title = this.getCurrentSection().title;
    const nextSection = tutorialSections[this.sectionsToComplete[1]];
    const buttons = [
      {
        action: () => {
          this.cancelTutorial();
          this.increaseTutorialProgress();
        },
        classes: 'btn btn-outline-primary',
        text: 'Close',
      },
    ];
    let text = `This concludes the tutorial for the ${title} tutorial.`;

    if (nextSection) {
      text += ` Click continue to begin the ${nextSection.title} tutorial.`;
      buttons.push({
        action: () => {
          this.handleContinueToNextSection();
        },
        classes: 'btn btn-primary',
        text: 'Continue',
      });
    } else {
      text += '<br><br>All tutorial sections have been complete. Please click the Close button to exit tutorial mode.';
    }

    return new TutorialStep(title + ' Tutorial Complete', text, buttons);
  }

  /**
   * Cancels the tutorial by closing ShepherdJS, unsubscribing to router changes, and resetting the app back to the dashboard out of tutorial mode
   */
  cancelTutorial(): void {
    this.shepherdService.cancel();
    this.shepherdService.onTourFinish('complete');
    this.routerSubscription.unsubscribe();
    this.router
      .navigateByUrl('/tutorial-mode-loading', { state: { mode: 'ending' } })
      .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
      .then(() => {
        this.inTutorialMode = false;
        this.router.navigateByUrl('/dashboard');
      });
  }

  /**
   * Completes the current section by closing ShepherdJS, increasing the progress, and continuing to the next section or canceling tutorial mode if all sections completed.
   */
  handleContinueToNextSection(): void {
    this.increaseTutorialProgress();
    this.shepherdService.complete();
    if (this.isTutorialComplete()) {
      this.cancelTutorial();
    } else {
      this.goToPageForTutorialSection();
    }
  }

  /**
   * Calls saveTutorialProgress to save the user's progress and removes the first element from sectionsToComplete
   */
  increaseTutorialProgress(): void {
    this.saveTutorialProgress();
    const newSectionsToComplete = this.sectionsToComplete.slice(1);
    this.sectionsToComplete = newSectionsToComplete;
  }

  /**
   * Takes the current tutorial section and saves its version to local storage under its title as a key
   */
  saveTutorialProgress(): void {
    let newProgress: LocallySavedProgress = {};
    const savedProgress: null | LocallySavedProgress = JSON.parse(localStorage.getItem('onboardTutorialProgress'));
    const completedSection = this.getCurrentSection();

    if (savedProgress) {
      newProgress = { ...savedProgress };
    }
    newProgress[completedSection.title] = { version: completedSection.version };

    localStorage.setItem('onboardTutorialProgress', JSON.stringify(newProgress));
  }

  /**
   * Sets all tutorial sections as complete
   */
  setTutorialAsComplete(): void {
    const completedSections: LocallySavedProgress = {};
    tutorialSections.forEach((section) => {
      completedSections[section.title] = {
        version: section.version,
      };
    });
    localStorage.setItem('onboardTutorialProgress', JSON.stringify(completedSections));
    this.sectionsToComplete = [];
  }

  /**
   * Reset the onboarding tutorial progress back to zero
   */
  resetOnboardingTutorialProgress(): void {
    localStorage.removeItem('onboardTutorialProgress');
    location.reload();
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
