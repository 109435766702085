export const teams = {
  next: 2,
  previous: null,
  count: 1,
  size: 10,
  current: 1,
  results: [
    {
      id: '66a6b246-1c97-41f4-a7c8-8eca51b010a5',
      members: [
        {
          id: '5b90730d-70e2-44e6-abef-ef80680cf2e6',
          first_name: 'John',
          last_name: 'Doe',
          organisation: {
            id: 'cc39a890-0962-47f4-a2a4-dec543615461',
            employees_count: 55,
            name: 'Example Org',
            feature_guard: {
              asset_edit_feature: false,
            },
            system_admin: {
              id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
              email: 'customer.portal.staging@gmail.com',
              created: '2023-08-25T13:08',
              updated: '2023-09-11T10:44',
              first_name: 'MFA edited',
              last_name: 'TEST',
              phone_number: null,
              location: null,
              last_login: null,
              job_title: null,
              status: 'Pending',
            },
            asset_owner: {
              id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
              email: 'jane.doe@example.org',
              created: '2023-09-25T10:42',
              updated: '2023-10-10T09:27',
              first_name: 'Jane',
              last_name: 'Doe',
              phone_number: null,
              location: null,
              last_login: null,
              job_title: null,
              status: 'Pending',
            },
            project_owner: {
              id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
              email: 'joshua.papa+test-34556@uk.clara.net',
              created: '2023-01-05T10:55',
              updated: '2023-07-10T12:11',
              first_name: 'Josh PO',
              last_name: 'Papa',
              phone_number: '+441234567890',
              location: 'UK',
              last_login: '2023-01-05T12:21:45Z',
              job_title: 'frontend',
              status: 'Pending',
            },
            address: {
              id: '153166f6-6b4b-458f-b98a-f567fc01b881',
              created: '2023-10-10T10:25',
              updated: '2023-10-10T10:25',
              name: null,
              street: 'Example Org Street',
              city: 'Examplinople',
              region: 'Exampleshire',
              country: 'Exampleland',
              postcode: 'EX1 12EX',
            },
            created: '2022-05-25T12:33',
            updated: '2023-10-10T10:25',
            logo: null,
            status: 'Active',
            phone: null,
            metadata: null,
            crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
          },
          email: 'john.doe@example.org',
          has_auth_user: true,
          phone_number: null,
          location: null,
          last_login: null,
          role: null,
          job_title: null,
          is_system_admin: false,
          is_asset_owner: false,
          is_project_owner: false,
          created: '2023-09-25T10:42',
          updated: '2023-09-25T15:08',
          status: 'Pending',
        },
        {
          id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
          first_name: 'Jane',
          last_name: 'Doe',
          organisation: {
            id: 'cc39a890-0962-47f4-a2a4-dec543615461',
            employees_count: 55,
            name: 'Example Org',
            feature_guard: {
              asset_edit_feature: false,
            },
            system_admin: {
              id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
              email: 'customer.portal.staging@gmail.com',
              created: '2023-08-25T13:08',
              updated: '2023-09-11T10:44',
              first_name: 'MFA edited',
              last_name: 'TEST',
              phone_number: null,
              location: null,
              last_login: null,
              job_title: null,
              status: 'Pending',
            },
            asset_owner: {
              id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
              email: 'jane.doe@example.org',
              created: '2023-09-25T10:42',
              updated: '2023-10-10T09:27',
              first_name: 'Jane',
              last_name: 'Doe',
              phone_number: null,
              location: null,
              last_login: null,
              job_title: null,
              status: 'Pending',
            },
            project_owner: {
              id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
              email: 'joshua.papa+test-34556@uk.clara.net',
              created: '2023-01-05T10:55',
              updated: '2023-07-10T12:11',
              first_name: 'Josh PO',
              last_name: 'Papa',
              phone_number: '+441234567890',
              location: 'UK',
              last_login: '2023-01-05T12:21:45Z',
              job_title: 'frontend',
              status: 'Pending',
            },
            address: {
              id: '153166f6-6b4b-458f-b98a-f567fc01b881',
              created: '2023-10-10T10:25',
              updated: '2023-10-10T10:25',
              name: null,
              street: 'Example Org Street',
              city: 'Examplinople',
              region: 'Exampleshire',
              country: 'Exampleland',
              postcode: 'EX1 12EX',
            },
            created: '2022-05-25T12:33',
            updated: '2023-10-10T10:25',
            logo: null,
            status: 'Active',
            phone: null,
            metadata: null,
            crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
          },
          email: 'jane.doe@example.org',
          has_auth_user: true,
          phone_number: null,
          location: null,
          last_login: null,
          role: null,
          job_title: null,
          is_system_admin: false,
          is_asset_owner: true,
          is_project_owner: false,
          created: '2023-09-25T10:42',
          updated: '2023-10-10T09:27',
          status: 'Pending',
        },
      ],
      organisation: {
        id: 'cc39a890-0962-47f4-a2a4-dec543615461',
        employees_count: 55,
        name: 'Example Org',
        feature_guard: {
          asset_edit_feature: false,
        },
        system_admin: {
          id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
          email: 'customer.portal.staging@gmail.com',
          created: '2023-08-25T13:08',
          updated: '2023-09-11T10:44',
          first_name: 'MFA edited',
          last_name: 'TEST',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        asset_owner: {
          id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
          email: 'jane.doe@example.org',
          created: '2023-09-25T10:42',
          updated: '2023-10-10T09:27',
          first_name: 'Jane',
          last_name: 'Doe',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        project_owner: {
          id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
          email: 'joshua.papa+test-34556@uk.clara.net',
          created: '2023-01-05T10:55',
          updated: '2023-07-10T12:11',
          first_name: 'Josh PO',
          last_name: 'Papa',
          phone_number: '+441234567890',
          location: 'UK',
          last_login: '2023-01-05T12:21:45Z',
          job_title: 'frontend',
          status: 'Pending',
        },
        address: {
          id: '153166f6-6b4b-458f-b98a-f567fc01b881',
          created: '2023-10-10T10:25',
          updated: '2023-10-10T10:25',
          name: null,
          street: 'Example Org Street',
          city: 'Examplinople',
          region: 'Exampleshire',
          country: 'Exampleland',
          postcode: 'EX1 12EX',
        },
        created: '2022-05-25T12:33',
        updated: '2023-10-10T10:25',
        logo: null,
        status: 'Active',
        phone: null,
        metadata: null,
        crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
      },
      created: '2023-10-01T10:12',
      updated: '2023-10-01T15:08',
      name: 'Example Team',
      created_by: null,
    },
  ],
};
