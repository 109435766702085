// Angular
import { Component } from '@angular/core';
import { UserPanelService } from 'src/metronic/theme/header/user-panel.service';
import { AuthService } from 'src/app/core/auth';

@Component({
  selector: 'kt-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
  user$ = this.authService.getUserData();

  constructor(private authService: AuthService, private userPanelService: UserPanelService) {}

  logout() {
    this.authService.logout();
  }

  close() {
    this.userPanelService.userPanelState.next(false);
  }
}
