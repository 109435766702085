import { TutorialStepConfig } from '../types/tutorial-step-config';
import { assetStepConfigs } from './asset-step-config.const';

import { dashboardStepConfigs } from './dashboard-step-configs.const';
import { notificationStepConfigs } from './notification-step-configs.const';
import { personalDetailsStepConfigs } from './personal-details-step-configs.const';
import { reportStepConfigs } from './report-step-configs.const';
import { roleStepConfigs } from './role-step-configs.const';
import { teamStepConfigs } from './team-step-configs.const';
import { userStepConfigs } from './user-step-configs.const';
import { vulnerabilityStepsConfig } from './vulnerability-step-configs.const';

const stepConfigs: { [key: string]: TutorialStepConfig[] } = {
  dashboard: dashboardStepConfigs,
  vulnerabilities: vulnerabilityStepsConfig,
  reports: reportStepConfigs,
  users: userStepConfigs,
  roles: roleStepConfigs,
  notifications: notificationStepConfigs,
  teams: teamStepConfigs,
  'personal details': personalDetailsStepConfigs,
  assets: assetStepConfigs,
};

export default stepConfigs;
