import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from 'src/app/models/page.model';
import { environment } from 'src/environments/environment';
import { NotificationDetails } from './notifications.model';
/**
 * Injectable
 */
@Injectable()
export class NotificationService {
  newNotification = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  getAllNotifications(pageNo?: number | string): Observable<Page<NotificationDetails>> {
    let params = {};
    if (pageNo) params = { page: pageNo, user_id: JSON.parse(localStorage.getItem('userDetails')).id };
    else params = { user_id: JSON.parse(localStorage.getItem('userDetails')).id };
    return this.http
      .get<Page<NotificationDetails>>(`${environment.notificationApi}/api/notifications`, { params })
      .pipe(
        map((res) => {
          if (res.results.length > 0) {
            this.newNotification.next(!res.results[0].is_read);
          }
          return res;
        })
      );
  }

  /**
   * @description edits a notification
   * @param payload
   */
  patchNotification(payload) {
    return this.http.patch<NotificationDetails>(
      `${environment.notificationApi}/api/notifications/${payload.id}`,
      payload
    );
  }

  getImportantNotifications() {
    const params = { user_id: JSON.parse(localStorage.getItem('userDetails')).id };
    return this.http.get<any>(`${environment.notificationApi}/api/notifications/important`, { params });
  }

  markAllAsRead() {
    const params = { user_id: JSON.parse(localStorage.getItem('userDetails')).id };
    return this.http.get<any>(`${environment.notificationApi}/api/notifications/markAllRead`, { params });
  }
}
