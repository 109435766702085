// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { AuthService } from '../../../../../app/core/auth';
import { TutorialOverlayService } from 'src/app/core/tutorial-overlay/tutorial-overlay.service';

@Component({
  selector: 'kt-user-profile4',
  templateUrl: './user-profile4.component.html',
  styleUrls: ['./user-profile4.component.scss'],
})
export class UserProfile4Component implements OnInit {
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  user$: Observable<unknown> = this.auth.getUserData();
  userRoleName: string;

  constructor(private auth: AuthService, public tutorialOverlayService: TutorialOverlayService) {}

  ngOnInit(): void {
    this.userRoleName = JSON.parse(localStorage.getItem('userDetails')).role.name;
  }
}
