import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TutorialOverlayService } from 'src/app/core/tutorial-overlay/tutorial-overlay.service';

@Component({
  selector: 'kt-reset-tutorial-modal',
  templateUrl: './reset-tutorial-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetTutorialModalComponent {
  constructor(private tutorialOverlayService: TutorialOverlayService, private modalService: NgbModal) {}

  onReset(): void {
    this.tutorialOverlayService.resetOnboardingTutorialProgress();
  }

  onDecline(): void {
    this.modalService.dismissAll();
  }
}
