<ng-container *ngIf="layout === 'subheader-v1'">
  <kt-subheader1
    [fixed]="fixed"
    [clear]="clear"
    [style]="style"
    [displayDesc]="displayDesc"
    [subheaderClasses]="subheaderClasses"
    [subheaderContainerClasses]="subheaderContainerClasses"
    [displayDaterangepicker]="false"
  ></kt-subheader1>
</ng-container>
