import { ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalUtilService } from './global-util.service';
import { InternationalisationService } from './internationalisation/internationalisation.service';

@NgModule({})
export class SharedSingletonModule {
  static forRoot(): ModuleWithProviders<SharedSingletonModule> {
    return {
      ngModule: SharedSingletonModule,
      providers: [GlobalUtilService, InternationalisationService],
    };
  }
}
