export const tutorialSections: { title: string; page: string; version: string }[] = [
  {
    title: 'dashboard',
    page: '/dashboard',
    version: '1.0.0',
  },
  {
    title: 'vulnerabilities',
    page: '/vulnerability',
    version: '1.0.0',
  },
  {
    title: 'reports',
    page: '/report',
    version: '1.0.0',
  },
  {
    title: 'notifications',
    page: '/dashboard',
    version: '1.0.0',
  },
  {
    title: 'assets',
    page: '/asset',
    version: '1.0.0',
  },
  {
    title: 'users',
    page: '/settings/users',
    version: '1.0.0',
  },
  {
    title: 'roles',
    page: '/settings/roles',
    version: '1.0.0',
  },
  {
    title: 'teams',
    page: '/settings/teams',
    version: '1.0.0',
  },
  // {
  //   title: 'personal details',
  //   page: '/settings/personalDetails',
  //   version: '1.0.0',
  // },
];
