import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
/**
 * Injectable
 */
@Injectable()
export class UserPanelService {
  /**
   * a subject which holds the state of the user panel as a boolean
   */
  public userPanelState: Subject<boolean> = new Subject();

  constructor() {}
}
