<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
  <div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
    <!--begin::Aside-->
    <div
      class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
      style="background-image: url('/assets/media/login-background/splash.png')"
    >
      <!--begin: Aside Container-->
      <div class="d-flex flex-row-fluid flex-column justify-content-between">
        <!--begin: Aside header-->
        <a href="https://sec-1.com/" class="flex-column-auto mt-5" style="margin-left: 25px">
          <svg viewBox="0 0 16.968 18.858" xmlns="http://www.w3.org/2000/svg" class="max-h-70px">
            <g fill="#fff">
              <path
                d="m11.269 6.123c-1.7 1.6-3.47 3.663-3.47 3.663l-3.815-3.253 3.525 7.1c1.864-4.413 9.459-12.133 9.459-12.133a70.613 70.613 0 0 0 -5.699 4.623z"
              />
              <path
                d="m13.206 12.153a13.872 13.872 0 0 1 -5.64 5.563 13.934 13.934 0 0 1 -5.644-5.563c-.789-1.642-1.083-4.606-.834-8.371l6.477-2.682 5.778 2.4.934-.718-6.715-2.782-7.431 3.074-.031.352c-.171 2.361-.279 6.691.917 9.174a15 15 0 0 0 6.326 6.154l.215.1.218-.1a14.859 14.859 0 0 0 6.33-6.154c1.039-2.158 1.094-5.715.979-8.157l-.964 1.234a15.51 15.51 0 0 1 -.915 6.476z"
              />
            </g>
          </svg>
        </a>
        <!--end: Aside header-->

        <!--begin: Aside content-->
        <div class="flex-column-fluid d-flex flex-column justify-content-center">
          <h3 class="font-size-h1 mb-5 text-white">CyberPortal</h3>
          <p class="font-weight-lighter text-white opacity-80"></p>
        </div>
        <!--end: Aside content-->

        <!--begin: Aside footer for desktop-->
        <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
          <div class="opacity-70 font-weight-bold text-white">&copy; {{ today | date: 'yyyy' }} Claranet</div>
          <div class="d-flex">
            <a href="https://www.claranet.co.uk/about" target="_blank" class="nav-link pr-3 pl-0">About</a>
            <a href="https://www.claranet.com/uk/sec-1" target="_blank" class="nav-link px-3">Team</a>
            <a href="https://www.claranet.co.uk/contact-us" target="_blank" class="nav-link pl-3 pr-0">Contact</a>
          </div>
        </div>
        <!--end: Aside footer for desktop-->
      </div>
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <!--		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">-->
    <!--			&lt;!&ndash;begin::Content header&ndash;&gt;-->
    <!--			<div-->
    <!--				class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">-->
    <!--				<span class="font-weight-bold text-dark-50">-->
    <!--					Don't have an account yet?-->
    <!--				</span>-->
    <!--				<a href="javascript:;" routerLink="/auth/register" class="font-weight-bold ml-2"-->
    <!--					id="kt_login_signup">{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a>-->
    <!--			</div>-->
    <!--			&lt;!&ndash;end::Content header&ndash;&gt;-->

    <router-outlet></router-outlet>
    <!--begin::Content footer for mobile-->
    <div
      class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5"
    >
      <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
        &copy; {{ today | date: 'yyyy' }} Claranet
      </div>
      <div class="d-flex order-1 order-sm-2 my-2">
        <a href="#" class="text-dark-75 text-hover-primary">Privacy</a>
        <a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>
        <a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>
      </div>
    </div>
    <!--end::Content footer for mobile-->
  </div>
  <!--end::Content-->
</div>
