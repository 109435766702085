// Angular
import { Component, HostListener, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../../html-class.service';
import { LayoutConfigService } from '../../../_base/layout';
import { UserPanelService } from '../user-panel.service';

@Component({
  selector: 'kt-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
  // Public properties
  headerLogo = '';
  asideSelfDisplay = true;
  headerMenuSelfDisplay = true;
  headerMobileClasses = '';

  panelState = false;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private uiService: HtmlClassService,
    private userPanelService: UserPanelService
  ) {}

  /**
   * toggles the panel if it is active
   * and the screen width is >= to 1000px
   *
   * @param event window size
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.panelState && window.innerWidth >= 1000) {
      this.togglePanel();
    }
  }
  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.headerMobileClasses = this.uiService.getClasses('header_mobile', true).toString();
    this.headerLogo = this.getLogoUrl();
    this.asideSelfDisplay = this.layoutConfigService.getConfig('aside.self.display');
    this.headerMenuSelfDisplay = this.layoutConfigService.getConfig('header.menu.self.display');
    this.userPanelService.userPanelState.subscribe((res) => (this.panelState = res));
  }

  getLogoUrl() {
    return `./assets/media/logos/logo.svg`;
  }

  /**
   * toggles the state on the user panel via the userPanelSevice
   */
  togglePanel() {
    this.panelState = !this.panelState;
    this.userPanelService.userPanelState.next(this.panelState);
  }
}
