// Angular
import { Injectable } from '@angular/core';
import { ConfigValues } from '../../../../app/core/_config/menu.config';
// RxJS
import { Subject, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth';

@Injectable()
export class MenuConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: ConfigValues;
  private assetFeatureGuard$: Observable<any>;

  /**
   * Service Constructor
   */
  constructor(private authService: AuthService) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
    this.assetFeatureGuard$ = this.authService.getUserData();
  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    return this.menuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: ConfigValues) {
    this.menuConfig = config;
  }
}
