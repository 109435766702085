export const reports = {
  next: null,
  previous: null,
  count: 5,
  size: 10,
  current: 1,
  results: [
    {
      uuid: '9177a3d6-3905-4c32-9ff3-b3e1da1f3e5e',
      company_uuid: 'ea92f535-b722-483a-9317-00218adfe3c4',
      file_name: 'report-05-10-2023.docx',
      uploaded_by: 'john.doe@example.org',
      visible: true,
      uploaded: '2023-10-05T11:14',
      updated: '2023-10-05T11:14',
    },
    {
      uuid: 'c7361d31-c81f-4aca-8453-65927cee0a83',
      company_uuid: 'ea92f535-b722-483a-9317-00218adfe3c4',
      file_name: 'monthly-report-october.docx',
      uploaded_by: 'jane.doe@example.org',
      visible: true,
      uploaded: '2023-10-10T12:11',
      updated: '2023-10-10T12:11',
    },
  ],
};
