import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { JsonConvert } from 'json2typescript';
import { StatisticsVulnerability } from '../../../../models/statistics-vulnerability.model';
import { AuthService } from 'src/app/core/auth';
import { GlobalUtilService } from 'src/app/shared/services/shared-singleton/global-util.service';
/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardResolverService implements Resolve<StatisticsVulnerability> {
  /**
   *
   * @param router
   * @param authService
   * @param http
   * @param globalUtilService
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private globalUtilService: GlobalUtilService
  ) {}
  /**
   * Resolve the customer_stats endpoint;
   */
  resolve(): any {
    return this.authService.getLoggedInContact().pipe(
      catchError((err) => {
        if (err.status === 403) {
          this.globalUtilService.openNotification(
            `User Permission Error - possible no organisation associated`,
            `Warning`
          );
        } else {
          this.globalUtilService.openNotification(`Error ${err?.status || 'unknown'} - network api`, `Warning`);
        }
        this.authService.logout();
        return EMPTY;
      }),
      switchMap((res: any) => {
        localStorage.setItem('userDetails', JSON.stringify(res));
        return this.getCustomerStats(res);
      })
    );
  }
  /**
   * Do the customer stats call;
   */
  getCustomerStats(data): Observable<any> {
    return this.http.get(`${environment.dirtyfixApi}/${data.organisation.id}/vulnerability/customer_statistics`).pipe(
      catchError((err) => {
        this.router.navigate(['server-error'], {
          state: {
            data: {
              status: err.status,
              msg: 'There was an error while attempting to load the dashboard. Please go back or try again later.',
            },
          },
        });
        return EMPTY;
      }),
      map((r: Record<string, unknown>) => {
        const stats = new JsonConvert().deserializeObject(r.data, StatisticsVulnerability);
        return stats;
      }),
      map((y) => y)
    );
  }
}
