// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
// NgBootstrap
import { NgbDropdownModule, NgbPopoverModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../app/core/core.module';
// CRUD Partials

import {
  QuickPanelComponent,
  QuickUserPanelComponent,
  ScrollTopComponent,
  SplashScreenComponent,
  Subheader1Component,
  UserProfile4Component,
} from './layout';
// General
import { PortletModule } from './content/general/portlet/portlet.module';
// Extra module
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserPanelComponent } from './layout/user-panel/user-panel.component';

@NgModule({
  declarations: [
    ScrollTopComponent,
    // topbar components
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SplashScreenComponent,
    Subheader1Component,
    UserProfile4Component,
    UserPanelComponent,
  ],
  exports: [
    PortletModule,

    // topbar components
    QuickPanelComponent,
    QuickUserPanelComponent,
    ScrollTopComponent,
    SplashScreenComponent,
    Subheader1Component,
    UserProfile4Component,
    UserPanelComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    NgApexchartsModule,
    // angular material modules
    MatProgressSpinnerModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbPopoverModule,
  ],
})
export class PartialsModule {}
