<ng-container *ngIf="user$ | async as _user">
  <div
    *ngIf="userRoleName === 'System Admin' || userRoleName === 'Project Owner' || tutorialOverlayService.inTutorialMode"
    class="topbar-item"
    ngbTooltip="Open settings"
    routerLink="/settings"
  >
    <div class="btn btn-icon w-auto d-flex align-items-center btn-lg pr-2">
      <ng-container *ngIf="greeting"> </ng-container>

      <!-- <span class="svg-icon svg-icon-primary svg-icon-2x settings-icon p-2"> -->

      <button type="button" style="background-color: white" class="btn btn-icon">
        <svg class="svg-icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.0231 10.2491C16.062 9.95733 16.0815 9.65589 16.0815 9.335C16.0815 9.02383 16.062 8.71267 16.0134 8.42095L17.9874 6.88456C18.1624 6.74843 18.211 6.48588 18.1041 6.2914L16.2371 3.06305C16.1204 2.84912 15.8773 2.78105 15.6634 2.84912L13.3393 3.78262C12.8531 3.41311 12.3378 3.10194 11.764 2.86857L11.414 0.398682C11.3751 0.165307 11.1806 0 10.9472 0H7.21323C6.97986 0 6.7951 0.165307 6.75621 0.398682L6.40614 2.86857C5.83243 3.10194 5.30734 3.42283 4.83086 3.78262L2.50684 2.84912C2.29291 2.77133 2.04981 2.84912 1.93312 3.06305L0.0758472 6.2914C-0.0408403 6.4956 -0.00194456 6.74843 0.192535 6.88456L2.1665 8.42095C2.11788 8.71267 2.07898 9.03356 2.07898 9.335C2.07898 9.63644 2.09843 9.95733 2.14705 10.2491L0.173087 11.7854C-0.00194455 11.9216 -0.0505642 12.1841 0.0563993 12.3786L1.9234 15.607C2.04009 15.8209 2.28319 15.8889 2.49711 15.8209L4.82114 14.8874C5.30734 15.2569 5.82271 15.5681 6.39642 15.8014L6.74648 18.2713C6.7951 18.5047 6.97986 18.67 7.21323 18.67H10.9472C11.1806 18.67 11.3751 18.5047 11.4043 18.2713L11.7543 15.8014C12.328 15.5681 12.8531 15.2569 13.3296 14.8874L15.6536 15.8209C15.8676 15.8987 16.1107 15.8209 16.2273 15.607L18.0943 12.3786C18.211 12.1647 18.1624 11.9216 17.9777 11.7854L16.0231 10.2491ZM9.08023 12.8356C7.15489 12.8356 5.57961 11.2603 5.57961 9.335C5.57961 7.40966 7.15489 5.83437 9.08023 5.83437C11.0056 5.83437 12.5809 7.40966 12.5809 9.335C12.5809 11.2603 11.0056 12.8356 9.08023 12.8356Z"
            fill="#34454E"
          />
        </svg>
      </button>
      <!-- </span> -->
    </div>
  </div>
</ng-container>
