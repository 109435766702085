import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TutorialOverlayService } from '../tutorial-overlay.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tutorialSections } from '../tutorial-sections.const';

/**
 * Modal to be shown at the start of the portal after logging in. User can start, dismiss, or skip tutorial mode
 */
@Component({
  selector: 'kt-begin-tutorial-modal',
  templateUrl: './begin-tutorial-modal.component.html',
  styleUrls: ['./begin-tutorial-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeginTutorialModalComponent implements OnInit {
  /**
   * Title of the modal. "Welcome" for new users, "Welcome Back" for users who have remaining tutorial sections
   */
  title = '';
  /**
   * Message of the modal. Changes depending on if a new user or a returning user with outstanding uncompleted tutorial sections
   */
  message = '';

  constructor(private tutorialOverlayService: TutorialOverlayService, private activeNgbModal: NgbActiveModal) {}

  /**
   * Calls method to set title and message
   */
  ngOnInit(): void {
    this.setTitleAndMessage();
  }

  /**
   * Sets the title and message depending on if user is new or has completed some tutorial sections
   */
  setTitleAndMessage(): void {
    const isNewUser = this.tutorialOverlayService.sectionsToComplete.length === tutorialSections.length;

    if (isNewUser) {
      this.title = 'Welcome';
      this.message =
        'Welcome to the Claranet Customer Portal. It appears to be your first time here. Would you like to take the onboarding tutorial? If so, the Customer Portal will restart in Tutorial Mode.';
    } else {
      this.title = 'Welcome Back';
      this.message =
        'Welcome back to the Claranet Customer Portal. Some sections of the onboarding tutorial are still incomplete. Would you like to continue from your last section?';
    }
  }

  /**
   * handles user clicking start tutorial mode button by calling initTutorialMode in the tutorial overlay service
   */
  onStart(): void {
    this.tutorialOverlayService.initTutorialMode();
    this.activeNgbModal.close();
  }

  /**
   * handles user clicking never ask me again button and completes all tutorial sections through the setTutorialAsComplete method on the service
   */
  onNeverAskAgain(): void {
    this.tutorialOverlayService.setTutorialAsComplete();
    this.activeNgbModal.close();
  }

  /**
   * handles user clicking ask me again later button to be prompted next time the load the portal
   */
  onAskAgainLater(): void {
    this.activeNgbModal.close();
  }
}
