export const customerStatistics = {
  total_all: 44,
  top_ten_vulns: [
    {
      title: 'Unsupported X-Frame-Options Header',
      total: 8,
    },
    {
      title: 'Security Headers Report',
      total: 7,
    },
    {
      title: 'Frameable URL could allow Clickjacking Attacks',
      total: 6,
    },
    {
      title: 'Cookie Without HttpOnly Flag Set',
      total: 6,
    },
    {
      title: 'Missing HTTP Security Headers',
      total: 5,
    },
    {
      title: 'Cacheable HTTPS Response',
      total: 4,
    },
    {
      title: 'Diffie-Hellman Ephemeral Key Exchange DoS Vulnerability (SSL/TLS, D(HE)ater) CVE-2002-20001',
      total: 3,
    },
    {
      title: 'SSL/TLS: Deprecated TLSv1.0 and TLSv1.1 Protocol Detection CVE-2011-3389, CVE-2015-0204',
      total: 3,
    },
    {
      title: 'Strict Transport Security not enabled',
      total: 1,
    },
    {
      title: 'TCP timestamps ',
      total: 1,
    },
  ],
  top_affected_hosts: [
    {
      host: 'Host Example One',
      total: 32,
    },
    {
      host: 'www.host-two.com',
      total: 8,
    },
    {
      host: 'Host Three',
      total: 4,
    },
  ],
  total_severity: {
    high: 22,
    medium: 10,
    low: 5,
    info: 5,
  },
  unfixed: 20,
  fixed: 10,
  resolved: 7,
  unresolved: 5,
  last_twelve_months: [
    {
      date: '2023-09-10',
      total: 5,
    },
    {
      date: '2023-08-10',
      total: 7,
    },
    {
      date: '2023-07-10',
      total: 10,
    },
    {
      date: '2023-06-10',
      total: 0,
    },
    {
      date: '2023-05-10',
      total: 0,
    },
    {
      date: '2023-04-10',
      total: 5,
    },
    {
      date: '2023-03-10',
      total: 5,
    },
    {
      date: '2023-02-10',
      total: 10,
    },
    {
      date: '2023-01-10',
      total: 5,
    },
    {
      date: '2022-12-10',
      total: 3,
    },
    {
      date: '2022-11-10',
      total: 0,
    },
    {
      date: '2022-10-10',
      total: 4,
    },
  ],
  monthly_total: 0,
  monthly_resolved: 0,
  monthly_unresolved: 0,
  previous_monthly_total: 0,
  unremediated: {
    daily_total: 4,
    weekly_total: 10,
    monthly_total: 20,
    older_than_a_month: 10,
  },
};
