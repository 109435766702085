export const roles = {
  next: 2,
  previous: null,
  count: 1,
  size: 10,
  current: 1,
  results: [
    {
      id: 'ae3d1bae-f799-4379-a0f2-447b44c6bd40',
      name: 'Example Role',
      permissions: {
        id: 'c43c9a71-2d25-4e9a-bad6-cde86da96847',
        inventory_items: {
          id: '9b37fd8a-7d8f-41a3-8919-5035115b875f',
          read: true,
          create: true,
          edit: true,
          delete: false,
          import_perm: false,
          export: false,
          assign: false,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        vulnerabilities: {
          id: '5039ba1e-26e2-4317-bc2d-8a9802dc8a9f',
          read: true,
          create: false,
          edit: false,
          delete: false,
          import_perm: false,
          export: false,
          assign: false,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        reporting: {
          id: '8e92d31c-b27d-4ba8-8b60-8b1f76fdec59',
          read: true,
          create: false,
          edit: false,
          delete: true,
          import_perm: false,
          export: false,
          assign: false,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        users: {
          id: '0ca1f4f9-8594-4e30-81b1-8f813542ed95',
          read: true,
          create: false,
          edit: false,
          delete: false,
          import_perm: false,
          export: false,
          assign: false,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        roles: {
          id: '3f5c4995-dfdb-4181-8003-683ae700e760',
          read: true,
          create: false,
          edit: false,
          delete: false,
          import_perm: false,
          export: false,
          assign: false,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        teams: {
          id: '417eece9-c513-41b0-93ae-e59c888ca171',
          read: true,
          create: false,
          edit: false,
          delete: false,
          import_perm: false,
          export: false,
          assign: true,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        project_perm: {
          id: '48083119-d9a0-4d75-932b-414667946ea3',
          read: true,
          create: false,
          edit: false,
          delete: false,
          import_perm: false,
          export: false,
          assign: false,
          created: '2023-05-26T10:57',
          updated: '2023-09-25T11:42',
        },
        created: '2023-05-26T10:57',
        updated: '2023-09-25T11:42',
      },
      company: {
        id: 'cc39a890-0962-47f4-a2a4-dec543615461',
        employees_count: 55,
        name: 'Example Org',
        feature_guard: {
          asset_edit_feature: false,
        },
        system_admin: {
          id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
          email: 'customer.portal.staging@gmail.com',
          created: '2023-08-25T13:08',
          updated: '2023-09-11T10:44',
          first_name: 'MFA edited',
          last_name: 'TEST',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        asset_owner: {
          id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
          email: 'jane.doe@example.org',
          created: '2023-09-25T10:42',
          updated: '2023-10-10T09:27',
          first_name: 'Jane',
          last_name: 'Doe',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        project_owner: {
          id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
          email: 'joshua.papa+test-34556@uk.clara.net',
          created: '2023-01-05T10:55',
          updated: '2023-07-10T12:11',
          first_name: 'Josh PO',
          last_name: 'Papa',
          phone_number: '+441234567890',
          location: 'UK',
          last_login: '2023-01-05T12:21:45Z',
          job_title: 'frontend',
          status: 'Pending',
        },
        address: {
          id: '153166f6-6b4b-458f-b98a-f567fc01b881',
          created: '2023-10-10T10:25',
          updated: '2023-10-10T10:25',
          name: null,
          street: 'Example Org Street',
          city: 'Examplinople',
          region: 'Exampleshire',
          country: 'Exampleland',
          postcode: 'EX1 12EX',
        },
        created: '2023-10-01T12:33',
        updated: '2023-10-01T10:25',
        logo: null,
        status: 'Active',
        phone: null,
        metadata: null,
        crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
      },
      users: [
        {
          id: '92b306d7-3fba-495d-b90b-331dd739e57d',
          organisation: {
            id: 'cc39a890-0962-47f4-a2a4-dec543615461',
            employees_count: 55,
            name: 'Example Org',
            feature_guard: {
              asset_edit_feature: false,
            },
            system_admin: {
              id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
              email: 'customer.portal.staging@gmail.com',
              created: '2023-08-25T13:08',
              updated: '2023-09-11T10:44',
              first_name: 'MFA edited',
              last_name: 'TEST',
              phone_number: null,
              location: null,
              last_login: null,
              job_title: null,
              status: 'Pending',
            },
            asset_owner: {
              id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
              email: 'jane.doe@example.org',
              created: '2023-09-25T10:42',
              updated: '2023-10-10T09:27',
              first_name: 'Jane',
              last_name: 'Doe',
              phone_number: null,
              location: null,
              last_login: null,
              job_title: null,
              status: 'Pending',
            },
            project_owner: {
              id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
              email: 'joshua.papa+test-34556@uk.clara.net',
              created: '2023-01-05T10:55',
              updated: '2023-07-10T12:11',
              first_name: 'Josh PO',
              last_name: 'Papa',
              phone_number: '+441234567890',
              location: 'UK',
              last_login: '2023-01-05T12:21:45Z',
              job_title: 'frontend',
              status: 'Pending',
            },
            address: {
              id: '153166f6-6b4b-458f-b98a-f567fc01b881',
              created: '2023-10-10T10:25',
              updated: '2023-10-10T10:25',
              name: null,
              street: 'Example Org Street',
              city: 'Examplinople',
              region: 'Exampleshire',
              country: 'Exampleland',
              postcode: 'EX1 12EX',
            },
            created: '2022-05-25T12:33',
            updated: '2023-10-10T10:25',
            logo: null,
            status: 'Active',
            phone: null,
            metadata: null,
            crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
          },
          phone_number: null,
          email: 'shruti.a@uk.clara.net',
          has_auth_user: true,
          created: '2022-05-13T12:53',
          updated: '2023-05-26T12:34',
          first_name: 'shruti',
          last_name: 'a',
          location: null,
          last_login: '2023-05-26T12:34:38Z',
          job_title: null,
          status: 'Pending',
        },
      ],
      created: '2023-10-01T12:33',
      updated: '2023-10-01T10:25',
    },
  ],
};
