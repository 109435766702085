// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective,
} from '../../metronic/_base/layout';
import { userInitialsPipe } from './_helpers/pipes/user-initials.pipe';
import { SharedModule } from '../shared/shared.module';
import { PermissionsDirective } from './permissions/permissions-directive';
import { AssetStatePipe } from './_helpers/pipes/asset-state.pipe';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    PermissionsDirective,
    // pipes
    userInitialsPipe,
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    AssetStatePipe,
  ],
  exports: [
    // directives
    AssetStatePipe,
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    PermissionsDirective,
    // pipes
    userInitialsPipe,
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
})
export class CoreModule {}
