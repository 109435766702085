// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from '../metronic/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';
import { DashboardResolverService } from './views/pages/dashboard/services/dashboard-resolver.service';
import { TutorialModeGuard } from './core/tutorial-overlay/guard/tutorial-mode.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then((m) => m.AuthModule) },
  { path: 'error', loadChildren: () => import('../metronic/error/error.module').then((m) => m.ErrorModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        resolve: { data: DashboardResolverService },
      },
      {
        path: 'asset',
        loadChildren: () => import('./views/pages/assets/assets.module').then((m) => m.AssetsModule),
      },
      {
        path: 'project',
        loadChildren: () => import('./views/pages/projects/projects.module').then((m) => m.ProjectsModule),
      },
      {
        path: 'report',
        loadChildren: () => import('./views/pages/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'vulnerability',
        loadChildren: () =>
          import('./views/pages/vulnerabilities/vulnerabilities.module').then((m) => m.VulnerabilitiesModule),
      },
      // {
      //   path: 'jira',
      //   loadChildren: () => import('./views/pages/jira/jira.module').then((m) => m.JiraModule),
      // },
      {
        path: 'settings',
        loadChildren: () => import('./views/pages/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'server-error',
        loadChildren: () => import('./views/pages/server-error/server-error.module').then((m) => m.ServerErrorModule),
      },
      {
        path: 'tutorial-mode-loading',
        canActivate: [TutorialModeGuard],
        loadChildren: () =>
          import('./views/pages/tutorial-mode-loading/tutorial-mode-loading.module').then(
            (m) => m.TutorialModeLoadingModule
          ),
      },
      {
        path: 'help-section',
        loadChildren: () => import('./views/pages/help-section/help-section.module').then((m) => m.HelpSectionModule),
      },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/error-1', pathMatch: 'full' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
