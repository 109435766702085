<!--begin::Signin-->
<div *ngIf="!maintenanceMode" class="login-form login-signin">
  <div class="text-center mb-10 mb-lg-20">
    <h3 class="font-size-h1"></h3>
    <p class="text-muted font-weight-bold"></p>
  </div>

  <!--begin::Form-->

  <form
    class="form"
    autocomplete="off"
    novalidate="novalidate"
    id="kt_login_form"
    *ngIf="{ isLoading: isLoading | async, isSigningInWithSSO: isSigningInWithSSO | async } as loginState"
  >
    <div class="col text-center">
      <button
        (click)="submit()"
        [disabled]="loginState.isLoading"
        type="button"
        [ngClass]="{
          'spinner spinner-right spinner-md spinner-light': loginState.isLoading
        }"
        class="btn btn-primary font-weight-bold px-9 py-4 my-3"
        data-cy="login"
      >
        {{ loginState.isLoading && loginState.isSigningInWithSSO ? 'Logging in with Claranet SSO' : 'Login' }}
      </button>
    </div>
  </form>
</div>

<div *ngIf="maintenanceMode">
  <h1 class="my-10" style="font-size: 6rem">We'll be back soon!</h1>
  <p style="font-size: 1.5rem">
    We're very sorry for the inconvenience but we're performing maintenance. <br />Please check back soon...
  </p>
  <p>- Development Team</p>
</div>
