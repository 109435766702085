import { TutorialStepConfig } from '../types/tutorial-step-config';
// import { clickElement, createPermissionsWarning } from './helpers';

export const userStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Users Page',
    text:
      'The users page shows a list of your users.<br><br>On this page you can: <ul><li>Search for a user</li><li>Go to ClaranetOnline to manage your users</li><li>Assign a new role to a user</li></ul>',
  },
  {
    title: 'Users List',
    text: "Users are listed here. You can click on a user's name to view their details page",
    attachTo: {
      element: '#tutorial-users-table',
      on: 'bottom',
    },
  },
  {
    title: 'Manage Users',
    text:
      'Users are managed by going to ClaranetOnline. You can open user manager on ClaranetOnline by clicking this button',
    attachTo: {
      element: '#claranetonline-button',
      on: 'right',
    },
  },
];
