import { TutorialStepConfig } from '../types/tutorial-step-config';
import { clickElement } from './helpers';

export const personalDetailsStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Personal Details Page',
    text: 'The personal details page allows you to view your details and make any change if necessary',
    attachTo: {
      element: '#tutorial-personal-details-page',
      on: 'left',
    },
  },
  {
    title: 'Edit Mode',
    text: 'Changing the page to edit mode is done so by clicking the Edit button',
    attachTo: {
      element: '#tutorial-personal-details-edit',
      on: 'bottom',
    },
    extraAction: () => {
      clickElement('tutorial-personal-details-edit');
    },
  },
  {
    title: 'Save or Cancel',
    text: 'You can save your changes by clicking the Save buttton or cancel them by clicking the Cancel button.',
    attachTo: {
      element: '#tutorial-personal-details-buttons',
      on: 'bottom',
    },
    waitForDomUpdate: true,
  },
];
