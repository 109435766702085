import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalUtilService } from '../../shared/services/shared-singleton/global-util.service';
import { environment } from 'src/environments/environment';
/**
 * Injectable
 */
@Injectable({ providedIn: 'root' })
export class ClientAuthInterceptor implements HttpInterceptor {
  constructor(private utilService: GlobalUtilService) {}
  /**
   * Set the client auth code for API gateway;
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // checks if a request for password reset is being made as client-code shouldn't be included in this request
    if (!req.url.includes('change_password')) {
      req = req.clone({
        headers: req.headers.set('client-code', environment.clientCode),
      });
    }
    if (req.url.split('//').length > 2) {
      const storage = JSON.parse(localStorage.getItem('userDetails')).organisation.id;
      req = req.clone({ url: this.fixClientUrl(req, storage) });
    }
    return next.handle(req);
  }
  /**
   * Save the ORG ID in memory;
   */
  replaceOrgStorage(s: string) {
    localStorage.setItem('org', JSON.stringify(s));
  }
  /**
   * If the ORG ID is missing in the request, get it from browser storage, save it and append it ot the request url;
   */
  fixClientUrl(request: HttpRequest<any>, orgId: string): string {
    const arr = request.url.split('//');
    let newUrl = '';
    for (let i = 0; i < arr.length; i++) {
      if (i === 1) {
        newUrl += arr[i] += '/' + orgId + '/';
        continue;
      }
      if (i === 0) {
        newUrl += arr[i] + '//';
        continue;
      }
      newUrl += arr[i];
    }
    return newUrl;
  }
}
