<div class="loading-spinner-container">
  <div *ngIf="isLoading; else flag" class="spinner-border"></div>
</div>
<ng-template #flag>
  <button class="btn p-0" (click)="onFlagClick()">
    <svg
      *ngIf="!notification.is_important"
      width="12"
      height="14"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.52941 2.11765L8.47059 0H0V18H2.11765V10.5882H7.41176L8.47059 12.7059H15.8824V2.11765H9.52941ZM13.7647 10.5882H9.52941L8.47059 8.47059H2.11765V2.11765H7.41176L8.47059 4.23529H13.7647V10.5882Z"
        fill="#34454E"
      />
    </svg>
    <svg
      *ngIf="notification.is_important"
      width="12"
      height="14"
      viewBox="0 0 16 18"
      fill="#3f4254"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0267 2.11765L9.6 0H0V18H2.13333V10.5882H8.10667L8.53333 12.7059H16V2.11765H10.0267Z"
        fill="#34454E"
      />
    </svg>
  </button>
</ng-template>
