<!-- begin:: Header Topbar -->

<ng-container>
  <ng-container *ngIf="userDisplay">
    <div class="topbar-item" ngbTooltip="Help">
      <div class="btn btn-icon w-auto d-flex align-items-center btn-lg pr-2">
        <button
          [ngbPopover]="popContent"
          #popOver="ngbPopover"
          container="body"
          popoverClass="my-notification-class"
          placement="bottom-left"
          [autoClose]="'outside'"
          type="button"
          style="background-color: white"
          class="btn btn-icon"
        >
          <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.4714 14.3193L4.49207 14.3484L4.52005 14.3705C4.63083 14.4581 4.76125 14.5046 4.90323 14.5046H7.23502C7.37708 14.5046 7.51419 14.4571 7.61918 14.3464C7.7259 14.2339 7.78917 14.0938 7.78917 13.9339V13.0253C7.78917 12.5981 7.91499 12.2589 8.15626 11.9896L8.15633 11.9897L8.16066 11.9845C8.43066 11.6664 8.85339 11.2873 9.43841 10.8467C10.0347 10.3976 10.5106 9.98816 10.8613 9.61868C11.2406 9.23717 11.5594 8.74832 11.8217 8.15919C12.1104 7.54913 12.25 6.83484 12.25 6.02391C12.25 4.45069 11.7383 3.1694 10.7008 2.20708C9.66488 1.22831 8.28925 0.75 6.60138 0.75C5.46936 0.75 4.45731 0.979607 3.57189 1.44595C2.68647 1.89446 1.99244 2.54138 1.495 3.38383C0.996276 4.21059 0.75 5.15553 0.75 6.21097V6.61182C0.75 6.7518 0.790929 6.88281 0.87232 6.99723L0.882528 7.01158L0.894647 7.02436C0.999657 7.13508 1.13609 7.20726 1.29757 7.20917L3.6245 7.31584L3.62448 7.3161H3.63594C3.77377 7.3161 3.92344 7.2829 4.03779 7.17238C4.15374 7.06032 4.19009 6.91088 4.19009 6.77215V6.21097C4.19009 5.57976 4.40196 5.07733 4.82113 4.68273C5.23904 4.28933 5.79851 4.08263 6.52535 4.08263C7.21808 4.08263 7.75258 4.28025 8.15393 4.65639L8.15389 4.65643L8.15783 4.65998C8.55395 5.01574 8.75922 5.4965 8.75922 6.1308C8.75922 6.70557 8.60484 7.17359 8.30776 7.55042C7.987 7.93884 7.49744 8.38801 6.83021 8.89807C6.04318 9.49341 5.43336 10.0696 5.00945 10.6279C4.56101 11.2001 4.34908 11.945 4.34908 12.8383V13.9339C4.34908 14.0739 4.39001 14.2049 4.4714 14.3193ZM4.51907 19.6376C4.90762 20.0473 5.40579 20.25 5.99309 20.25C6.5661 20.25 7.05492 20.0454 7.44176 19.6376C7.82936 19.2289 8.01728 18.7103 8.01728 18.1027C8.01728 17.4976 7.83077 16.9865 7.44181 16.5944C7.0692 16.1835 6.57817 15.9821 5.99309 15.9821C5.40689 15.9821 4.9095 16.1841 4.52126 16.5922C4.13078 16.9845 3.94355 17.4964 3.94355 18.1027C3.94355 18.7103 4.13147 19.2289 4.51907 19.6376Z"
              fill="#34454E"
              stroke="#34454E"
              stroke-width="0.5"
            />
          </svg>
        </button>
      </div>

      <ng-template #popContent>
        <div class="card">
          <div class="card-body py-0">
            <div class="help-option">
              <p (click)="openUserManual()" style="cursor: pointer" class="mt-3">User Manual</p>
            </div>
            <div class="help-option">
              <p style="cursor: pointer"><a routerLink="/help-section" target="_blank">Help Section</a></p>
            </div>
            <div class="help-option" *ngIf="tutorialOverlayService.sectionsToComplete.length">
              <p style="cursor: pointer" (click)="onStartTutorialMode()">Tutorial Mode</p>
            </div>
            <div class="help-option">
              <p style="cursor: pointer" (click)="onResetTutorialProgress()">Reset Tutorial Progress</p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <kt-user-profile4></kt-user-profile4>
    <ng-container *ngIf="userLayout === 'offcanvas'">
      <!--begin: User bar -->
      <div style="position: relative" class="topbar-item">
        <ng-template #popContent><kt-notifications (closePopover)="popOver.close()"></kt-notifications></ng-template>
        <ng-template #popTitle>
          <div class="card-title d-flex justify-space-between">
            <h3 class="card-label">Notification</h3>
            <button type="button" class="close" aria-label="Close" (click)="popoverClosed()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div></ng-template
        >
        <button
          type="button"
          style="background-color: white"
          class="btn btn-icon"
          [ngbPopover]="popContent"
          #popOver="ngbPopover"
          container="body"
          id="tutorial-notifications-open"
          popoverClass="my-notification-class"
          placement="bottom-right"
          [autoClose]="'outside'"
        >
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.00274 19.3373C9.04177 19.3373 9.89188 18.4757 9.89188 17.4225H6.11359C6.11359 18.4757 6.95426 19.3373 8.00274 19.3373ZM13.6702 13.593V8.80599C13.6702 5.8668 12.1211 3.4063 9.41959 2.75527V2.10424C9.41959 1.30961 8.78673 0.668152 8.00274 0.668152C7.21874 0.668152 6.58588 1.30961 6.58588 2.10424V2.75527C3.87496 3.4063 2.33531 5.85722 2.33531 8.80599V13.593L0.446167 15.5077V16.4651H15.5593V15.5077L13.6702 13.593Z"
              fill="#34454E"
            />
          </svg>
        </button>

        <!-- <button style="background-color: white" class="btn btn-icon" (click)="toggleNotification()">
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.00274 19.3373C9.04177 19.3373 9.89188 18.4757 9.89188 17.4225H6.11359C6.11359 18.4757 6.95426 19.3373 8.00274 19.3373ZM13.6702 13.593V8.80599C13.6702 5.8668 12.1211 3.4063 9.41959 2.75527V2.10424C9.41959 1.30961 8.78673 0.668152 8.00274 0.668152C7.21874 0.668152 6.58588 1.30961 6.58588 2.10424V2.75527C3.87496 3.4063 2.33531 5.85722 2.33531 8.80599V13.593L0.446167 15.5077V16.4651H15.5593V15.5077L13.6702 13.593Z"
              fill="#34454E"
            />
          </svg>
        </button> -->
        <svg
          *ngIf="notificationService.newNotification | async"
          style="position: absolute; top: 45; left: 29"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.66741" cy="6.66741" r="6.66741" fill="#E00000" />
        </svg>
      </div>
      <div class="topbar-item" ngbTooltip="Open ClaranetOnline">
        <a [href]="claranetonlineUrl" class="btn btn-icon claranetonline-button ml-2">
          <img alt="ClaranetOnline Logo" src="assets/media/logos/claranetonline-logo-blue-grey.svg" />
        </a>
      </div>
      <kt-quick-user-panel></kt-quick-user-panel>
      <!--end: User bar -->
    </ng-container>
  </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
