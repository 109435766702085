import { TutorialStepConfig } from '../types/tutorial-step-config';
import { createPermissionsWarning } from './helpers';

export const roleStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Roles Page',
    text:
      'The roles page shows a list of your roles.<br><br>On this page you can: <ul><li>Search for a role</li><li>Create a new role</li><li>Edit or delete a role</li><li>See the users assigned to a role</li></ul>',
  },
  {
    title: 'Roles List',
    text:
      createPermissionsWarning(['Roles', 'edit'], ['Roles', 'delete']) +
      'Roles are listed here. <br><br>You can view which roles are assigned to a role by clicking its name in the Role column.<br><br>Roles can be edited by clicking the view button in the Permissions column.<br><br>The delete button in the Actions column will delete a role.',
    attachTo: {
      element: '#tutorial-roles-table',
      on: 'bottom',
    },
  },
  {
    title: 'Create a Role',
    text:
      createPermissionsWarning(['Roles', 'create']) +
      'The process of creating a role is begun by clicking the + Add Role button',
    attachTo: {
      element: '#add',
      on: 'bottom',
    },
    // extraAction: () => {
    //   clickElement('add');
    // },
  },
  // {
  //   title: 'Create Role Popup',
  //   text: 'A role can be created from scratch or using another role as a base template.',
  //   waitForDomUpdate: true,
  //   extraAction:() => {
  //     const nameInput = document.getElementById('tutorial-roles-name-input') as HTMLInputElement;
  //     nameInput.value = 'New Role Example';
  //   }
  // },
  // {
  //   title: 'Create Role Permissions',
  //   text:
  //     'These checkboxes can be ticked to mark which permissions this role should have.<br><br>By default, some checkboxes are locked to protect important actions. Only users with administrative roles like the System Admin can have these permissions.',
  // },
  // {
  //   title: 'On Completion',
  //   text: 'The popup will close when the role is succesfully created allowing you to continue with another task.',
  // },
];
