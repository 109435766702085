<ng-container *ngIf="user$ | async as _user">
  <!-- begin::Offcanvas Toolbar Quick Actions -->

  <!-- <div *ngIf="user$ | async as user__"> -->
  <div class="topbar-item" ngbTooltip="User's Info">
    <button
      [ngbPopover]="popContent"
      #popOver="ngbPopover"
      container="body"
      popoverClass="my-notification-class"
      placement="bottom-right"
      [autoClose]="'outside'"
      class="btn btn-icon ml-2 p-2"
      style="background-color: white; width: 10rem"
    >
      <span class="text-truncate">{{ loggedInUser.first_name + ' ' + loggedInUser.last_name }}</span>
    </button>

    <ng-template #popContent>
      <div class="card">
        <div class="card-body">
          <div class="pb-5">
            <h5 class="text-primary">{{ loggedInUser.first_name + ' ' + loggedInUser.last_name }}</h5>
            <span class="text-primary">{{ loggedInUser.email }}</span>
          </div>

          <div class="text-center">
            <button (click)="logout()" data-cy="logout" class="btn btn-outline-primary btn-bold py-2 mb-3">
              Sign Out
            </button>

            <p
              (click)="resetPassword()"
              class="text-primary text-hover-danger cursor-pointer m-0"
              style="text-decoration: underline"
            >
              Reset Password
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- </div> -->
  <!-- end::Offcanvas Toolbar Quick User Actions -->
</ng-container>
