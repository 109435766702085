import { assets } from './assets.const';
import { customerStatistics } from './customer-statistics.const';
import { notifications } from './notifications.const';
import { reports } from './reports.const';
import { roles } from './roles.const';
import { teams } from './teams.const';
import { users } from './users.const';
import { vulnerabilityFilters } from './vulnerability-filters.const';
import { vulnerabilityList } from './vulnerability-list.const';

const mockData = {
  customerStatistics,
  vulnerabilityFilters,
  vulnerabilityList,
  reports,
  notifications,
  assets,
  users,
  roles,
  teams,
};

export default mockData;
