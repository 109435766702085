<!-- begin:: Footer -->
<div class="footer bg-white py-4 d-flex flex-lg-column align-items-center" id="kt_footer" [ngClass]="footerClasses">
  <div [ngClass]="footerContainerClasses" class="d-flex flex-md-row justify-content-between">
    <!--begin::Logo-->
    <div class="text-dark">
      <img [attr.src]="footerLogo" class="footer-logo" />
    </div>
    <div class="d-flex mt-2">
      <a class="mr-5" [href]="claranetonlineUrl"> ClaranetOnline </a>
      <a class="mr-5" routerLink="/help-section" target="_blank">Help Section</a>
      <a class="mr-5" href="https://www.claranetcybersecurity.com/contact-us" target="_blank">Contact</a>
      <a href="https://www.claranet.com/uk/services/cybersecurity" target="_blank">About Cybersecurity</a>
    </div>
    <!--end::Logo-->
  </div>
</div>
<!-- end:: Footer -->
