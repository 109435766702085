<div class="modal-header"><h3 class="mb-0">Reset Onboarding Tutorial Progress</h3></div>
<div class="modal-body">
  <p>
    Would you like to reset your onboarding tutorial progress? This will remove any progress you have made during the
    tutorial and restart you at the first tutorial section.
  </p>
  <p class="mb-10">If yes, the Portal will refresh to trigger the start tutorial popup.</p>
  <div class="d-flex justify-content-center">
    <button class="btn btn-primary mr-7 px-8 py-2" (click)="onDecline()">Close</button>
    <button class="btn btn-primary px-8 py-2" (click)="onReset()">Reset</button>
  </div>
</div>
