// NGRX
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from '../../../environments/environment';

/**
 * State of the app in ngrx store
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppState {}

/**
 * Router state in the store
 */
export const reducers: ActionReducerMap<AppState> = { router: routerReducer };

/**
 * @ignore
 */
export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];
