import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('StatisticsVulnerability')
export class StatisticsVulnerability {
  @JsonProperty('total_all', Number, true)
  totalAll: number = undefined;

  @JsonProperty('top_ten_vulns', [Any], true)
  topTenVulns: any = undefined;

  @JsonProperty('top_affected_hosts', [Any], true)
  topAffectedHosts: any = undefined;

  @JsonProperty('total_severity', Object, true)
  totalSeverity: any = undefined;

  @JsonProperty('unfixed', Number, true)
  unfixed: number = undefined;

  @JsonProperty('fixed', Number, true)
  fixed: number = undefined;

  @JsonProperty('resolved', Number, true)
  resolved: number = undefined;

  @JsonProperty('unresolved', Number, true)
  unresolved: number = undefined;

  @JsonProperty('last_twelve_months', [Object], true)
  lastTwelveMonths: any = undefined;

  @JsonProperty('monthly_total', Number, true)
  monthlyTotal: number = undefined;

  @JsonProperty('monthly_resolved', Number, true)
  monthlyResolved: number = undefined;

  @JsonProperty('previous_monthly_total', Number, true)
  previousMonthlyTotal: number = undefined;

  @JsonProperty('unremediated', Object, true)
  unremediated: number = undefined;
}
