type SelectMethod = 'id' | 'class' | 'tag';

export const clickElement = (element: string, selectMethod: SelectMethod = 'id'): void => {
  let selectedElement: HTMLElement;

  switch (selectMethod) {
    case 'id':
      selectedElement = document.getElementById(element);
      break;
    case 'class':
      selectedElement = document.getElementsByClassName(element)[0] as HTMLElement;
      break;
    case 'tag':
      selectedElement = document.getElementsByTagName(element)[0] as HTMLElement;
      break;
    default:
      break;
  }

  selectedElement.click();
};
