<div class="modal-header"><img src="assets/media/logos/claranet.png" alt="Claranet Cyber Security logo" /></div>
<div class="modal-body p-10">
  <h3 class="mb-1">{{ title }}</h3>
  <p class="mb-10">{{ message }}</p>
  <h6 class="mb-0">Tutorial Mode:</h6>
  <p class="mb-12">
    During Tutorial Mode, you will only be able to interact with the “Close” and “Continue” buttons in the tutorial
    popup guide. Data throughout the portal will be fabricated and permissions will be disabled to enhance your tutorial
    experience. A note will be displayed in the popup guide if the current step requires a specific permission to
    complete. To leave Tutorial Mode at any time, click the “Close” button in the tutorial popup guide. Tutorial Mode
    will also end automatically at the end of the tutorial.
  </p>
  <div class="d-flex justify-content-between align-items-end">
    <button class="btn btn-link p-0" (click)="onNeverAskAgain()">Don't show me this again</button>
    <div>
      <button class="btn btn-outline-primary mr-5" (click)="onAskAgainLater()">Ask Again Later</button>
      <button class="btn btn-primary" (click)="onStart()">Start Tutorial</button>
    </div>
  </div>
</div>
