import { TutorialStepConfig } from '../types/tutorial-step-config';
import { clickElement, createPermissionsWarning } from './helpers';

export const teamStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Teams Page',
    text:
      'The teams page shows a list of your teams.<br><br>On this page you can: <ul><li>Search for a team</li><li>Create a new team</li><li>Edit or delete a team</li><li>Assign users to a team</li></ul>',
  },
  {
    title: 'Teams List - Edit / Delete',
    text:
      createPermissionsWarning(['Teams', 'edit'], ['Teams', 'delete']) +
      'Teams are listed here.<br><br>You can edit a team or change who is assigned to a team by clicking its name in the Team column.<br><br>Teams are deleted by clicking the delete button located in the actions column.',
    attachTo: {
      element: '#tutorial-teams-table',
      on: 'bottom',
    },
  },
  {
    title: 'Create a Team',
    text:
      createPermissionsWarning(['Teams', 'create']) +
      'The process of creating a team is begun by clicking the + Add Team button',
    attachTo: {
      element: '#add',
      on: 'bottom',
    },
    extraAction: () => {
      clickElement('add');
    },
  },
  {
    title: 'Create Team Popup',
    text:
      createPermissionsWarning(['Teams', 'create']) +
      'The create team popup requires a team name entering.<br><br>Users can be assigned during creation or later on by editing the team.<br><br>To create a team without any users, click the red cross under the user search input.',
    attachTo: {
      element: '.modal-content',
      on: 'left',
    },
    waitForDomUpdate: true,
    extraAction: () => {
      clickElement('modal show', 'class');
    },
  },
  {
    title: 'On Completion',
    text: 'The popup will close when the team is succesfully created allowing you to continue with another task.',
  },
];
