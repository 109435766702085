<ng-container *ngIf="user$ | async as _user">
  <!-- begin::Offcanvas Toolbar Quick Actions -->
  <!-- Overlay closes panel when clicked -->
  <div class="overlay" (click)="close()"></div>
  <div class="sidenav" [ngStyle]="{ overflow: 'hidden' }">
    <div class="offcanvas-content" [ngStyle]="{ height: '100vh', overflow: 'hidden' }">
      <div
        [perfectScrollbar]="{ wheelPropagation: false }"
        class="scroll"
        [ngStyle]="{ height: '600px', position: 'relative' }"
      >
        <div class="d-flex align-items-center userdetails p-10">
          <div class="symbol symbol-100 mr-5">
            <div class="symbol-label rounded-circle"></div>
          </div>
          <div class="user-section d-flex flex-column w-100">
            <div>
              <a href="#" class="btn btn-light font-weight-bold font-size-h6 px-4 py-1">
                {{ _user.username }}
              </a>
            </div>
            <div class="text-light mt-6">{{ _user.firstName }} {{ _user.lastName }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-6">
                  <ng-container>
                    <span
                      id="userEmail"
                      class="navi-text text-light"
                      style="text-overflow: hidden; overflow: hidden; width: 60px"
                    >
                      {{ _user.email }}
                    </span>
                  </ng-container>
                </span>
              </a>
            </div>
            <div>
              <a
                (click)="logout()"
                data-cy="logout"
                class="btn btn-outline-light btn-bold text-light text-hover-primary py-2"
              >
                Sign Out
              </a>
            </div>
          </div>
        </div>
        <div class="flex-column d-flex align-items-center py-20 px-4">
          <div class="quick-panel-item font-size-h5 font-weight-bold cursor-pointer" routerLink="/settings">
            Settings
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Offcanvas Toolbar Quick User Actions -->
</ng-container>
