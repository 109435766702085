import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationDetails } from '../notifications.model';

@Component({
  selector: 'kt-notification-flag',
  templateUrl: './notification-flag.component.html',
  styleUrls: ['./notification-flag.component.scss'],
})
export class NotificationFlagComponent {
  @Input() notification: NotificationDetails;
  @Input() isLoading: boolean | undefined;
  @Output() flagClick = new EventEmitter<NotificationDetails>();

  onFlagClick(): void {
    this.flagClick.emit(this.notification);
  }
}
