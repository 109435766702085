import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { take } from 'rxjs/operators';
/**
 * Injectable
 */
@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private auth0: Auth0Service) {}

  /**
   * initiates /authorize call
   *
   * @returns void stream from auth0
   */
  public login() {
    this.auth0.loginWithRedirect();
  }

  /**
   * Starts the password reset flow in Auth0
   */
  public passwordReset() {
    return this.http.post(
      `https://${environment.auth0Domain}/dbconnections/change_password`,
      {
        client_id: environment.auth0ClientId,
        email: JSON.parse(localStorage.getItem('userDetails')).email,
        connection: 'Username-Password-Authentication',
      },
      { responseType: 'text' }
    );
  }

  /**
   * from the auth0 isAuthenticated Observable
   * based on whether the user is authenticated
   *
   * @returns a boolean value
   */
  public isAuthenticated(): Observable<boolean> {
    return this.auth0.isAuthenticated$.pipe(take(1));
  }

  /**
   * the user data observable from auth0
   *
   * @returns the user observable from auth0
   */
  public getUserData(): Observable<any> {
    return this.auth0.user$.pipe(take(1));
  }

  /**
   * Get the user's access token from auth0
   *
   * @returns auth0 access token
   */
  public getAccessToken(): Observable<string> {
    return this.auth0.getAccessTokenSilently().pipe(take(1));
  }

  /**
   * logs the user out from auth0
   *
   */
  public logout(): void {
    const env = environment as any;
    this.auth0.logout({
      localOnly: false,
      returnTo: env.auth0LogoutRedirect || '',
    });
  }

  /**
   * company/contact data from shepherd api
   *
   * @returns http observable for company/contact data from shepherd
   */
  getLoggedInContact(): Observable<any> {
    return this.http.get(`${environment.shepherdApi}/users/me`);
  }
}
