// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutConfigService } from '../../../_base/layout';
import { NotificationService } from '../notifications/notifications.service';
import { TutorialOverlayService } from 'src/app/core/tutorial-overlay/tutorial-overlay.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ResetTutorialModalComponent } from 'src/app/views/pages/settings/components/reset-tutorial-modal/reset-tutorial-modal.component';
import { InternationalisationService } from 'src/app/shared/services/shared-singleton/internationalisation/internationalisation.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @ViewChild('popOver', { static: false }) popOver: NgbPopover;
  searchDisplay = true;
  quickActionsDisplay = true;
  quickPanelDisplay = true;
  userDisplay = true;
  userLayout = 'offcanvas';
  userDropdownStyle = 'light';
  //notifications flag for unread notes
  newNotifications = false;
  notificationViewOpen = false;
  claranetonlineUrl: string;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public tutorialOverlayService: TutorialOverlayService,
    public notificationService: NotificationService,
    private internationalisationService: InternationalisationService,
    private modalService: NgbModal
  ) {
    this.searchDisplay = this.layoutConfigService.getConfig('extras.search.display');
    this.quickActionsDisplay = this.layoutConfigService.getConfig('extras.quick-actions.display');
    this.quickPanelDisplay = this.layoutConfigService.getConfig('extras.quick-panel.display');
    this.userDisplay = this.layoutConfigService.getConfig('extras.user.display');
    this.userLayout = this.layoutConfigService.getConfig('extras.user.layout');
    this.userDropdownStyle = this.layoutConfigService.getConfig('extras.user.dropdown.style');
  }

  ngOnInit(): void {
    this.claranetonlineUrl = this.internationalisationService.buildClaranetonlineUrl();
  }

  toggleNotification() {
    this.notificationViewOpen = !this.notificationViewOpen;
  }

  /**
   * Opens user manual PDF in a new tab
   */
  openUserManual() {
    window.open('/assets/media/documents/CST - Customer Portal - Quick Start Guide v1.0.pdf');
  }

  /**
   * handles user clicking start tutorial mode button by calling initTutorialMode in the tutorial overlay service
   */
  onStartTutorialMode(): void {
    if (!this.tutorialOverlayService.inTutorialMode) {
      this.tutorialOverlayService.initTutorialMode();
    }
    this.popOver.close();
  }

  /**
   * Reset the onboard tutorial progress by calling the resetOnboardingTutorialProgress method on the TutorialOverlayService and then informing the user with a snackbar message
   */
  onResetTutorialProgress(): void {
    this.modalService.open(ResetTutorialModalComponent, { centered: true });
  }
}
