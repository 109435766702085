<div class="card-body p-0 notifications-container">
  <div class="card-header p-0">
    <div class="card-title">
      <h5 class="card-label mb-0 pt-1 pb-1">Notifications</h5>
      <button
        class="btn btn-link-secondary btn-icon btn-md close p-0"
        id="tutorial-notifications-close"
        style="height: auto"
        (click)="popoverClosed()"
      >
        <svg class="mb-0" width="10" height="12" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 2.27061L12.59 0.82428L7 6.55829L1.41 0.82428L0 2.27061L5.59 8.00462L0 13.7386L1.41 15.185L7 9.45094L12.59 15.185L14 13.7386L8.41 8.00462L14 2.27061Z"
            fill="#34454E"
          />
        </svg>
      </button>
    </div>
  </div>

  <div class="container p-0">
    <div class="mark-all">
      <div *ngIf="isLoadingMarkAllRead | async; else markAllAsRead" class="spinner-border"></div>
      <ng-template #markAllAsRead>
        <button class="btn p-0" (click)="markAllRead()">Mark all as read</button>
      </ng-template>
    </div>
    <!-- Drop down -->
    <!-- FLAGGED Notification start -->
    <!-- Dropdown bar start -->
    <div class="flag-note-dropdown-bar">
      <div>
        <svg width="12" height="14" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0267 2.11765L9.6 0H0V18H2.13333V10.5882H8.10667L8.53333 12.7059H16V2.11765H10.0267Z"
            fill="#34454E"
          />
        </svg>
      </div>
      <div id="tutorial-notifications-toggle-dropdown">
        <!-- Down Arrow -->
        <svg
          *ngIf="!toggleFlagged"
          style="cursor: pointer"
          (click)="toggleFlaggedNotes()"
          width="12"
          height="7"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.645 0L7 5.04768L12.355 0L14 1.55398L7 8.16667L0 1.55398L1.645 0Z" fill="#34454E" />
        </svg>
        <!-- Down Arrow -->
        <!-- Up Arrow -->
        <svg
          *ngIf="toggleFlagged"
          style="cursor: pointer"
          (click)="toggleFlaggedNotes()"
          width="12"
          height="7"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.355 8.16669L7 3.119L1.645 8.16669L1.35853e-07 6.6127L7 1.94152e-05L14 6.61271L12.355 8.16669Z"
            fill="#34454E"
          />
        </svg>

        <!-- Up Arrow -->
      </div>
    </div>
    <!-- Dropdown bar end -->
    <!-- Flagged Notification container start -->
    <div *ngIf="toggleFlagged" style="overflow: auto; max-height: 150px" class="flagged-container">
      <div
        *ngIf="isLoadingImpNotifications | async; else flaggedNotifications"
        class="d-flex justify-content-center py-8"
      >
        <div class="spinner-border"></div>
      </div>
      <ng-template #flaggedNotifications>
        <div class="mark-all d-flex justify-content-center py-6" *ngIf="notifications_imp.length === 0">
          No flagged notifications
        </div>
        <div *ngFor="let notification_imp of notifications_imp; let i = index">
          <div class="notification-flaged d-flex justify-space-between">
            <div class="col-11 note-text">
              <p class="note-title p-0 m-0">{{ notification_imp.subject }}</p>
              <p class="note-desc p-0 m-0">{{ notification_imp.message }}</p>
            </div>
            <div class="col-1 p-0">
              <kt-notification-flag
                [notification]="notification_imp"
                [isLoading]="whichFlagsAreToggling[notification_imp.id]"
                (flagClick)="toggleFlag($event)"
              ></kt-notification-flag>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Flagged Notification container end -->

    <!-- FLAGGED Notification end -->

    <div style="overflow: auto; max-height: 300px">
      <!--NON-FLAGGED Notification start -->
      <div *ngIf="isLoadingNotifications | async; else nonFlaggedNotifications" class="text-center py-15">
        <div class="spinner-border"></div>
      </div>
      <ng-template #nonFlaggedNotifications>
        <div *ngFor="let notification of notifications; let i = index">
          <div
            class="notification d-flex justify-space-between"
            [ngClass]="notification.is_read ? 'read-notifications' : 'unread-notifications'"
          >
            <div class="col-11 note-text" (click)="markAsRead(i)" style="cursor: pointer">
              <div class="note-title-container">
                <p class="note-title bold p-0 m-0">{{ notification.subject }}</p>
                <svg
                  *ngIf="!notification.is_read"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3.665" cy="3.665" r="3.665" fill="#E00000" />
                </svg>
              </div>

              <p class="note-desc p-0 m-0">{{ notification.message }}</p>
            </div>
            <div class="col-1 p-0" id="tutorial-notifications-flag">
              <kt-notification-flag
                [notification]="notification"
                [isLoading]="whichFlagsAreToggling[notification.id]"
                (flagClick)="toggleFlag($event)"
              ></kt-notification-flag>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- NON-FLAGGED Notification end -->
    </div>
    <div
      class="mark-all d-flex justify-content-center mt-4 mb-2"
      id="tutorial-notifications-load-more"
      *ngIf="notificationPage?.next"
    >
      <div *ngIf="isLoadingMoreNotifications | async; else loadMoreButton" class="spinner-border"></div>
      <ng-template #loadMoreButton>
        <button class="btn p-0" (click)="loadMore()">Load more...</button>
      </ng-template>
    </div>
  </div>
</div>
