import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LoginResolver implements Resolve<boolean> {
  constructor(private authService: AuthService, private router: Router) {}

  resolve(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      switchMap((isAuth) => {
        if (isAuth) {
          this.router.navigate(['dashboard']);
          return of(true);
        }

        return this.authService.getAccessToken();
      }),
      map((token) => !!token),
      catchError(() => of(false))
    );
  }
}
