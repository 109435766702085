import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { StatisticsVulnerability } from '../../../models/statistics-vulnerability.model';
/**
 * Injectable
 */
@Injectable()
export class GlobalUtilService {
  /**
   * stores stats data for the dashboard
   */
  private statisticsData = new BehaviorSubject<StatisticsVulnerability | Record<any, any>>({});
  /**
   *
   * @ignore
   */
  constructor(private matSnackBar: MatSnackBar) {}
  /**
   * get behaviour subject
   */
  getStatisticsData() {
    return this.statisticsData.value;
  }
  /**
   * set behaviour subject
   */
  setStatisticsData(value: StatisticsVulnerability) {
    return this.statisticsData.next(value);
  }

  /**
   *
   * sorts the passed data in a descending order
   *
   * @param data
   * @param key
   */
  sortByDates(data, key) {
    return data.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
  }

  /**
   * opens a popup for the user with the passed in message, action and/or error
   *
   * @param msg
   * @param action
   * @param error
   */
  openNotification(msg: string, action: string, error?: boolean) {
    this.matSnackBar.open(`${msg}`, action, { duration: 5000 });
  }
}
