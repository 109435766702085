// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../html-class.service';
import { InternationalisationService } from 'src/app/shared/services/shared-singleton/internationalisation/internationalisation.service';
// Object-Path

@Component({
  selector: 'kt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  footerClasses = '';
  footerContainerClasses = '';
  footerLogo: string;
  claranetonlineUrl: string;

  /**
   * Component constructor
   *
   * @param uiClasses: HtmlClassService
   */
  constructor(private uiClasses: HtmlClassService, private internationalisationService: InternationalisationService) {}

  // Jira Issue Collector

  submitIssue() {
    (window as any).showCollectorDialog();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.footerLogo = this.getLogoUrl();
    this.footerClasses = this.uiClasses.getClasses('footer', true).toString();
    this.footerContainerClasses = this.uiClasses.getClasses('footer_container', true).toString();
    this.claranetonlineUrl = this.internationalisationService.buildClaranetonlineUrl();
  }

  /**
   * @description gets the url for logo
   * @returns string
   */
  private getLogoUrl(): string {
    return './assets/media/logos/claranet.png';
  }
}
