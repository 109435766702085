import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class InternationalisationService {
  /**
   * Build the ClaranetOnline link depending on the env and user's country
   */
  buildClaranetonlineUrl(): string {
    return environment.claranetonlineUrlMap[
      JSON.parse(localStorage.getItem('userDetails')).organisation.country.country_code || 'UK'
    ];
  }
}
