import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import mockData from './mock-data/mock-data';

/**
 * Checks the URL of the outgoing HTTP request. Returns a mock response with mock data if the URL meets one of the conditions. Returns the next handler if no conditions are met.
 *
 * @param req Outgoing HTTP request. Property UrlWithParams is used
 * @param next Next handler to be returned if no match
 * @returns mock data or next handler
 */
const checkUrl = (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpResponse<any> | HttpEvent<any>> => {
  const url = req.urlWithParams;

  // vulnerability list
  if (url.includes('vulnerability?customer=')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.vulnerabilityList, status: 'success' } }));
  }
  // vulnerability filters
  if (url.includes('filters/vulnerabilities')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.vulnerabilityFilters, status: 'success' } }));
  }
  // dashboard statistics
  if (url.includes('customer_statistics')) {
    return of(new HttpResponse({ status: 200, body: { data: mockData.customerStatistics, status: 'success' } }));
  }
  // reports
  if (url.includes('reports_s3')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.reports, status: 'success' } }));
  }
  // notifications
  if (url.includes('notifications')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.notifications, status: 'success' } }));
  }
  // assets
  if (url.includes('assets')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.assets, status: 'success' } }));
  }
  // users
  if (url.includes('users?page')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.users, status: 'success' } }));
  }
  // roles
  if (url.includes('roles')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.roles, status: 'success' } }));
  }
  // teams
  if (url.includes('teams')) {
    return of(new HttpResponse({ status: 200, body: { ...mockData.teams, status: 'success' } }));
  }

  return next.handle(req);
};

export default checkUrl;
