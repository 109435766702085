import { createAction, props } from '@ngrx/store';
import { ShepherdFilter } from '../models/shepherd-filter.model';
import { FormMode } from '../components/vulnerability-search-filter/search-filter.component';

/**
 * Initiate filters request
 */
export const filtersRequested = createAction('[ShepherdFilter] Filters Requested');
/**
 * Filters request fail. Pass a payload containing error data
 */
export const requestFailed = createAction('[ShepherdFilter] Request Failed', props<{ error: any }>());
/**
 * Filters request success. Pass a payload containing filters data
 */
export const filtersLoaded = createAction(
  '[ShepherdFilter] Filters Request Success',
  props<{ filters: ShepherdFilter[] }>()
);
/**
 * Initiate new filter. Pass a payload containing the new filter name
 */
export const createFilter = createAction('[ShepherdFilter] Add New Filter', props<{ newFilterName: string }>());
/**
 * New filter API POST success. Pass a payload containing the new filter data
 */
export const createFilterSuccess = createAction(
  '[ShepherdFilter] Filter API Post Success',
  props<{ filter: ShepherdFilter }>()
);
/**
 * Select a filter by its id. Pass a payload containing the filter id
 */
export const selectFilterById = createAction(
  '[ShepherdFilter] Select Filter Id',
  props<{ selectFilterId: string | null }>()
);
/**
 * Set the form mode. Pass a payload containing a number to indicate if the form should be in edit mode
 */
export const filterFormMode = createAction('[ShepherdFilter] Filter Form Edit Flag', props<{ mode: FormMode }>());
/**
 * Update a filter in the store. Pass a payload containing the filter data
 */
export const updateFilter = createAction('[ShepherdFilter] Update Filter Store', props<{ filter: ShepherdFilter }>());
/**
 * Send a request to the API to update the filter on the backend. Pass a payload containing the query
 */
export const updateFilterShepherd = createAction(
  '[ShepherdFilter] Update Filter API',
  props<{ query: Record<string, unknown> }>()
);
/**
 * Send a request to the API to create a filter on the backend. Pass a payload containing the query
 */
export const createFilterShepherd = createAction(
  '[ShepherdFilter] Create Filter API',
  props<{ query: Record<string, unknown> }>()
);
/**
 * Activate the filter. Pass a payload containing a boolean value
 */
export const activateFilter = createAction('[ShepherdFilter] Activate Filter Search', props<{ activate: boolean }>());
/**
 * Delete a filter from the store. Pass a payload containing the filter id
 */
export const deleteFilterSuccess = createAction('[ShepherdFilter] Delete Filter Store', props<{ id: string }>());
/**
 * Open a ui message. Pass a payload containing the message and message type
 */
export const openUserNotifier = createAction(
  '[ShepherdFilter] Open UI Message',
  props<{ messageUser: { message: string; type: string } }>()
);
