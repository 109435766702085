import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TutorialOverlayService } from '../tutorial-overlay.service';

/**
 * Guard to prevent the user navigating to the tutorial mode loading page if they are not in tutorial mode
 */
@Injectable({
  providedIn: 'root',
})
export class TutorialModeGuard implements CanActivate {
  constructor(private tutorialOverlayService: TutorialOverlayService, private router: Router) {}

  canActivate(): boolean {
    if (this.tutorialOverlayService.inTutorialMode) return true;
    else {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }
}
