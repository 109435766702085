export const users = {
  next: 2,
  previous: null,
  count: 2,
  size: 10,
  current: 1,
  results: [
    {
      id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
      first_name: 'Jane',
      last_name: 'Doe',
      organisation: {
        id: 'cc39a890-0962-47f4-a2a4-dec543615461',
        employees_count: 56,
        name: 'Example Org',
        feature_guard: {
          asset_edit_feature: false,
        },
        system_admin: {
          id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
          email: 'customer.portal.staging@gmail.com',
          created: '2023-08-25T13:08',
          updated: '2023-09-11T10:44',
          first_name: 'MFA edited',
          last_name: 'TEST',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        asset_owner: {
          id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
          email: 'jane.doe@example.org',
          created: '2023-09-25T10:42',
          updated: '2023-10-10T09:27',
          first_name: 'Jane',
          last_name: 'Doe',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        project_owner: {
          id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
          email: 'joshua.papa+test-34556@uk.clara.net',
          created: '2023-01-05T10:55',
          updated: '2023-07-10T12:11',
          first_name: 'Josh PO',
          last_name: 'Papa',
          phone_number: '+441234567890',
          location: 'UK',
          last_login: '2023-01-05T12:21:45Z',
          job_title: 'frontend',
          status: 'Pending',
        },
        address: {
          id: '153166f6-6b4b-458f-b98a-f567fc01b881',
          created: '2023-10-10T10:25',
          updated: '2023-10-10T10:25',
          name: null,
          street: 'Example Org Street',
          city: 'Examplinople',
          region: 'Exampleshire',
          country: 'Exampleland',
          postcode: 'EX1 12EX',
        },
        created: '2022-05-25T12:33',
        updated: '2023-10-10T10:25',
        logo: null,
        status: 'Active',
        phone: null,
        metadata: null,
        crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
      },
      email: 'jane.doe@example.org',
      has_auth_user: true,
      phone_number: null,
      location: null,
      last_login: null,
      role: {
        id: 'f7c4355d-19e4-400e-8315-2bfd0bf0ce67',
        name: 'Asset Owner',
        permissions: {
          id: 'fc4633b1-2996-4f0a-a9e9-7d261912124d',
          inventory_items: {
            id: '131b0be2-1ca4-41e1-ba2f-3555a299c413',
            read: true,
            create: true,
            edit: true,
            delete: true,
            import_perm: true,
            export: true,
            assign: true,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          vulnerabilities: {
            id: 'dbd1788d-f853-4940-8dd5-4427f0323884',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          reporting: {
            id: '63a9da54-0c05-48dc-b1c2-c968b9c74389',
            read: true,
            create: true,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          users: {
            id: '2ba2c385-96ed-42b2-8efe-b6a2de8cfba5',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          roles: {
            id: '2ba2c385-96ed-42b2-8efe-b6a2de8cfba5',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          teams: {
            id: '2ba2c385-96ed-42b2-8efe-b6a2de8cfba5',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          project_perm: {
            id: '87499ae6-824f-43c4-b88e-2784a518dd35',
            read: false,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-07-10T12:11',
            updated: '2023-07-10T12:11',
          },
          created: '2023-07-10T12:11',
          updated: '2023-07-10T12:11',
        },
        company: {
          id: 'cc39a890-0962-47f4-a2a4-dec543615461',
          employees_count: 56,
          name: 'Example Org',
          feature_guard: {
            asset_edit_feature: false,
          },
          system_admin: {
            id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
            email: 'customer.portal.staging@gmail.com',
            created: '2023-08-25T13:08',
            updated: '2023-09-11T10:44',
            first_name: 'MFA edited',
            last_name: 'TEST',
            phone_number: null,
            location: null,
            last_login: null,
            job_title: null,
            status: 'Pending',
          },
          asset_owner: {
            id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
            email: 'jane.doe@example.org',
            created: '2023-09-25T10:42',
            updated: '2023-10-10T09:27',
            first_name: 'Jane',
            last_name: 'Doe',
            phone_number: null,
            location: null,
            last_login: null,
            job_title: null,
            status: 'Pending',
          },
          project_owner: {
            id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
            email: 'joshua.papa+test-34556@uk.clara.net',
            created: '2023-01-05T10:55',
            updated: '2023-07-10T12:11',
            first_name: 'Josh PO',
            last_name: 'Papa',
            phone_number: '+441234567890',
            location: 'UK',
            last_login: '2023-01-05T12:21:45Z',
            job_title: 'frontend',
            status: 'Pending',
          },
          address: {
            id: '153166f6-6b4b-458f-b98a-f567fc01b881',
            created: '2023-10-10T10:25',
            updated: '2023-10-10T10:25',
            name: null,
            street: 'Example Org Street',
            city: 'Examplinople',
            region: 'Exampleshire',
            country: 'Exampleland',
            postcode: 'EX1 12EX',
          },
          created: '2022-05-25T12:33',
          updated: '2023-10-10T10:25',
          logo: null,
          status: 'Active',
          phone: null,
          metadata: null,
          crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
        },
        created: '2023-07-10T12:11',
        updated: '2023-07-10T12:11',
      },
      job_title: null,
      is_system_admin: false,
      is_asset_owner: true,
      is_project_owner: false,
      created: '2023-09-25T10:42',
      updated: '2023-10-10T09:27',
      status: 'Pending',
    },
    {
      id: '5b90730d-70e2-44e6-abef-ef80680cf2e6',
      first_name: 'John',
      last_name: 'Doe',
      organisation: {
        id: 'cc39a890-0962-47f4-a2a4-dec543615461',
        employees_count: 56,
        name: 'Example Org',
        feature_guard: {
          asset_edit_feature: false,
        },
        system_admin: {
          id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
          email: 'customer.portal.staging@gmail.com',
          created: '2023-08-25T13:08',
          updated: '2023-09-11T10:44',
          first_name: 'MFA edited',
          last_name: 'TEST',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        asset_owner: {
          id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
          email: 'jane.doe@example.org',
          created: '2023-09-25T10:42',
          updated: '2023-10-10T09:27',
          first_name: 'Jane',
          last_name: 'Doe',
          phone_number: null,
          location: null,
          last_login: null,
          job_title: null,
          status: 'Pending',
        },
        project_owner: {
          id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
          email: 'joshua.papa+test-34556@uk.clara.net',
          created: '2023-01-05T10:55',
          updated: '2023-07-10T12:11',
          first_name: 'Josh PO',
          last_name: 'Papa',
          phone_number: '+441234567890',
          location: 'UK',
          last_login: '2023-01-05T12:21:45Z',
          job_title: 'frontend',
          status: 'Pending',
        },
        address: {
          id: '153166f6-6b4b-458f-b98a-f567fc01b881',
          created: '2023-10-10T10:25',
          updated: '2023-10-10T10:25',
          name: null,
          street: 'Example Org Street',
          city: 'Examplinople',
          region: 'Exampleshire',
          country: 'Exampleland',
          postcode: 'EX1 12EX',
        },
        created: '2022-05-25T12:33',
        updated: '2023-10-10T10:25',
        logo: null,
        status: 'Active',
        phone: null,
        metadata: null,
        crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
      },
      email: 'john.doe@example.org',
      has_auth_user: true,
      phone_number: null,
      location: null,
      last_login: null,
      role: {
        id: 'd36985b3-70f5-42fd-abec-c7dc65a7dc54',
        name: 'Read Only',
        permissions: {
          id: '01260f95-e040-43aa-8346-d27672aef364',
          inventory_items: {
            id: 'e8778b58-b8ba-418f-95ee-1d919a8b3b8a',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          vulnerabilities: {
            id: '184bbffd-acbb-4052-964e-d85bd64b109b',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          reporting: {
            id: 'c8c50081-ae76-41d0-ac13-d8952c8fc5db',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          users: {
            id: '95c20c93-56dc-451a-bc2b-cf817dc0de8f',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          roles: {
            id: '184bbffd-acbb-4052-964e-d85bd64b109b',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          teams: {
            id: '184bbffd-acbb-4052-964e-d85bd64b109b',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          project_perm: {
            id: '184bbffd-acbb-4052-964e-d85bd64b109b',
            read: true,
            create: false,
            edit: false,
            delete: false,
            import_perm: false,
            export: false,
            assign: false,
            created: '2023-05-25T07:46',
            updated: '2023-08-30T11:17',
          },
          created: '2023-05-25T07:46',
          updated: '2023-08-30T11:17',
        },
        company: {
          id: 'cc39a890-0962-47f4-a2a4-dec543615461',
          employees_count: 56,
          name: 'Example Org',
          feature_guard: {
            asset_edit_feature: false,
          },
          system_admin: {
            id: 'e240ed1f-cd21-4061-a1d0-5885d1d5dd64',
            email: 'customer.portal.staging@gmail.com',
            created: '2023-08-25T13:08',
            updated: '2023-09-11T10:44',
            first_name: 'MFA edited',
            last_name: 'TEST',
            phone_number: null,
            location: null,
            last_login: null,
            job_title: null,
            status: 'Pending',
          },
          asset_owner: {
            id: '20f1ba86-4d1d-4952-bceb-39f5752c74a4',
            email: 'jane.doe@example.org',
            created: '2023-09-25T10:42',
            updated: '2023-10-10T09:27',
            first_name: 'Jane',
            last_name: 'Doe',
            phone_number: null,
            location: null,
            last_login: null,
            job_title: null,
            status: 'Pending',
          },
          project_owner: {
            id: '88bc6802-c71e-419b-bbf9-9e9753b11650',
            email: 'joshua.papa+test-34556@uk.clara.net',
            created: '2023-01-05T10:55',
            updated: '2023-07-10T12:11',
            first_name: 'Josh PO',
            last_name: 'Papa',
            phone_number: '+441234567890',
            location: 'UK',
            last_login: '2023-01-05T12:21:45Z',
            job_title: 'frontend',
            status: 'Pending',
          },
          address: {
            id: '153166f6-6b4b-458f-b98a-f567fc01b881',
            created: '2023-10-10T10:25',
            updated: '2023-10-10T10:25',
            name: null,
            street: 'Example Org Street',
            city: 'Examplinople',
            region: 'Exampleshire',
            country: 'Exampleland',
            postcode: 'EX1 12EX',
          },
          created: '2022-05-25T12:33',
          updated: '2023-10-10T10:25',
          logo: null,
          status: 'Active',
          phone: null,
          metadata: null,
          crypto_key: 'w8V3ELPsKIPf5EDu8WUlBjbqB5-12Wse-WxKhOul80s=',
        },
        created: '2023-05-25T07:46',
        updated: '2023-08-30T11:17',
      },
      job_title: null,
      is_system_admin: false,
      is_asset_owner: false,
      is_project_owner: false,
      created: '2023-09-25T10:42',
      updated: '2023-09-25T15:08',
      status: 'Pending',
    },
  ],
};
