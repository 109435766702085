/**
 * @ignore
 */
export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {
        title: 'Dashboard',
        desc: 'Latest updates and statistic charts',
      },
    },
    vulnerability: {
      page: {
        list: {
          title: 'Vulnerabilities',
          desc: 'Vulnerabilities management screen',
        },
      },
    },
    asset: {
      page: {
        title: 'Inventory',
        desc: 'Asset management screen',
      },
    },
    contact: {
      page: {
        title: 'Contacts',
        desc: 'Contact management screen',
      },
    },
    report: {
      page: {
        title: 'Reports',
        desc: 'Report management screen',
      },
    },
    faq: {
      page: {
        title: 'Support Centre',
        desc: 'Frequently asked questions',
      },
    },
    project: {
      page: {
        title: 'Projects',
        desc: 'Project management screen',
      },
    },
    'server-error': {
      page: {
        title: 'Error',
        desc: 'Error during loading page screen',
      },
    },
    'tutorial-mode-loading': {
      page: {
        title: '',
        desc: '',
      },
    },
    'help-section': {
      page: {
        title: 'Help Section',
        desc: 'Help Section with instructional guides',
      },
    },
    // Metronic default error pages
    error: {
      404: {
        page: { title: '404 Not Found', desc: '', subheader: false },
      },
      403: {
        page: { title: '403 Access Forbidden', desc: '', subheader: false },
      },
    },
    settings: {
      page: {
        users: {
          title: 'Company Settings',
          subtitle: 'User List',
          desc: 'Users',
        },
        roles: {
          title: 'Company Settings',
          desc: 'Roles',
          subtitle: 'Roles',
        },
        teams: {
          title: 'Company Settings',
          desc: 'Teams',
          subtitle: 'Teams',
        },
        // integrations: {
        //   title: 'Personal Settings',
        //   desc: 'Integrations',
        //   subtitle: 'Integrations',
        // },
        // personalDetails: {
        //   title: 'Personal Settings',
        //   desc: 'Personal Details',
        //   subtitle: 'Personal Details',
        // },
      },
    },
  };

  /**
   * returns the pages configuration object
   */
  public get configs(): any {
    return this.defaults;
  }
}
