import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Page } from 'src/app/models/page.model';
import { GlobalUtilService } from 'src/app/shared/services/shared-singleton/global-util.service';
import { NotificationDetails } from './notifications.model';
import { NotificationService } from './notifications.service';

@Component({
  selector: 'kt-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  /**
   * Close new filter popover on vulnerability page whenever it emits.
   */
  @Output() closePopover: EventEmitter<void> = new EventEmitter();
  notifications: NotificationDetails[] = [];
  notifications_imp: NotificationDetails[] = [];
  notificationPage: Page<NotificationDetails>;
  toggleFlagged = false;
  isLoadingNotifications = new BehaviorSubject(false);
  isLoadingMoreNotifications = new BehaviorSubject(false);
  isLoadingImpNotifications = new BehaviorSubject(false);
  isLoadingMarkAllRead = new BehaviorSubject(false);
  whichFlagsAreToggling: { assetId?: boolean } = {};

  constructor(
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private globalUtilService: GlobalUtilService
  ) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  markAllRead() {
    this.isLoadingMarkAllRead.next(true);
    this.notificationService
      .markAllAsRead()
      .pipe(
        catchError((err) => {
          this.isLoadingMarkAllRead.next(false);
          return this.handleError(err, 'Unable to mark all as read');
        })
      )
      .subscribe(() => {
        this.isLoadingMarkAllRead.next(false);
      });
  }

  markAsRead(index) {
    this.notifications[index].is_read = true;
    this.notificationService
      .patchNotification(this.notifications[index])
      .pipe(catchError((err) => this.handleError(err, 'Unable to mark as read')))
      .subscribe();
    this.cdr.markForCheck();
  }

  toggleFlag(notificationToToggle: NotificationDetails): void {
    const changedNotification = cloneDeep(notificationToToggle);
    changedNotification.is_important = !changedNotification.is_important;
    this.whichFlagsAreToggling[notificationToToggle.id] = true;
    this.notificationService
      .patchNotification(changedNotification)
      .pipe(
        catchError((err) => {
          this.whichFlagsAreToggling[notificationToToggle.id] = false;
          return this.handleError(err, 'Unable to toggle flag');
        })
      )
      .subscribe(() => {
        this.updateUIAfterFlagToggle(changedNotification);
      });
  }

  updateUIAfterFlagToggle(changedNotification: NotificationDetails): void {
    // add to or remove from the flagged notifications array updating the ui
    const newImportantNotifications = cloneDeep(this.notifications_imp);
    if (changedNotification.is_important) {
      newImportantNotifications.push(changedNotification);
      this.notifications_imp = newImportantNotifications;
    } else {
      this.notifications_imp = newImportantNotifications.filter(
        (notification) => notification.id !== changedNotification.id
      );
    }

    // update notifications array so ui is updated
    const newNotificationList = cloneDeep(this.notifications);
    const notificationInMainList = newNotificationList.find(
      (notification) => notification.id === changedNotification.id
    );
    // flagged notifications may not be loaded in the notification list due to being on a unloaded page
    // check if present to prevent a TypeError
    if (notificationInMainList) {
      notificationInMainList.is_important = !notificationInMainList.is_important;
      this.notifications = newNotificationList;
    }

    this.whichFlagsAreToggling[changedNotification.id] = false;
  }

  /**
   * Emits on closePopover property
   */
  popoverClosed(): void {
    this.closePopover.emit();
  }

  toggleFlaggedNotes() {
    if (!this.toggleFlagged) {
      this.getImportantNotifications();
    }
    this.toggleFlagged = !this.toggleFlagged;
  }

  getNotifications(pageNumber?: number | string): void {
    // only activate is loading notifications spinner without a page number
    // this will only be during init
    if (!pageNumber) this.isLoadingNotifications.next(true);
    this.notificationService
      .getAllNotifications(pageNumber)
      .pipe(
        catchError((err) => {
          this.isLoadingNotifications.next(false);
          this.isLoadingMoreNotifications.next(false);
          return this.handleError(err, 'Unable to get notifications');
        })
      )
      .subscribe((res) => {
        this.notifications.push(...res.results);
        this.notificationPage = res;
        this.isLoadingNotifications.next(false);
        this.isLoadingMoreNotifications.next(false);
        this.cdr.markForCheck();
      });
  }

  loadMore(): void {
    this.isLoadingMoreNotifications.next(true);
    this.getNotifications(this.notificationPage.next);
  }

  ngOnDestroy(): void {
    this.notificationService.getAllNotifications().subscribe();
  }

  /**
   * Handles error and show a snackbar with the error
   *
   * @param err error to be displayed
   * @returns Empty observale
   */
  handleError(err: HttpErrorResponse, msg: string) {
    this.globalUtilService.openNotification(`${msg}: ${err.status ? err.status : 400}`, 'Warning');
    this.cdr.markForCheck();
    console.error(err);
    return EMPTY;
  }

  private getImportantNotifications(): void {
    this.isLoadingImpNotifications.next(true);
    this.notificationService
      .getImportantNotifications()
      .pipe(
        catchError((err) => {
          this.isLoadingImpNotifications.next(false);
          return this.handleError(err, 'Unable to get important notifications');
        })
      )
      .subscribe((res) => {
        this.notifications_imp = res.result;
        this.isLoadingImpNotifications.next(false);
      });
  }
}
