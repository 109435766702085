// Angular
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Auth
import { AuthService } from '../../../../core/auth';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * boolean to check if the user is using SSO to sign in
   */
  isSigningInWithSSO = new BehaviorSubject(false);
  /**
   * boolean to check if login is loading
   */
  isLoading = new BehaviorSubject(false);
  /**
   * Maintenance Boolean - manually change this and deploy to set the maintenance page state
   */
  maintenanceMode = false;

  /**
   *
   * @param auth
   * @param router
   */
  constructor(private auth: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {}

  /**
   * on init check if the user is logging in with SSO from the resolver
   * if so, only go ahead with SSO
   */
  ngOnInit() {
    if (this.maintenanceMode) {
      this.router.navigate(['/maintenance-page']);
      return;
    }

    this.activatedRoute.data.subscribe((res) => {
      if (res.isSSOLogin) {
        this.isSigningInWithSSO.next(true);
        this.isLoading.next(true);
        this.auth.login();
      }
    });
  }

  /**
   *
   * attempts to log the user in with auth0 via the authService
   */
  submit() {
    this.isLoading.next(true);
    this.auth.login();
  }

  /**
   * @ignore
   */
  ngOnDestroy() {}
}
