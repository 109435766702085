export const vulnerabilityFilters = {
  next: null,
  previous: null,
  count: 1,
  size: 10,
  current: 1,
  results: [
    {
      id: '638x304b-e5ae-48fe-23c6-282c23d07406',
      name: 'My Custom Filter',
      query: {
        name: 'My Custom Filter',
        tags: null,
        value: null,
        impact: {
          low: false,
          high: true,
          info: false,
          medium: false,
        },
        status: [0],
        toDate: null,
        option1: null,
        option2: null,
        fromDate: null,
        dateRange: null,
        probability: {
          low: false,
          high: true,
          info: false,
          medium: false,
        },
        extraFilters: [],
      },
    },
    {
      id: '638f304e-e5ae-48fe-a3c6-282c23d07403',
      name: 'Unfixed Vulnerabilities',
      query: {
        name: 'Unfixed Vulnerabilities',
        tags: null,
        value: null,
        impact: {
          low: false,
          high: true,
          info: false,
          medium: false,
        },
        status: [0],
        toDate: null,
        option1: null,
        option2: null,
        fromDate: null,
        dateRange: null,
        probability: {
          low: false,
          high: true,
          info: false,
          medium: false,
        },
        extraFilters: [],
      },
    },
    {
      id: '638f304w-e5ae-48fe-a3c1-282c23d0c406',
      name: 'Low Impact Only',
      query: {
        name: 'Low Impact Only',
        tags: null,
        value: null,
        impact: {
          low: false,
          high: true,
          info: false,
          medium: false,
        },
        status: [0],
        toDate: null,
        option1: null,
        option2: null,
        fromDate: null,
        dateRange: null,
        probability: {
          low: false,
          high: true,
          info: false,
          medium: false,
        },
        extraFilters: [],
      },
    },
  ],
};
