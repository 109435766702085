<!-- splash screen -->
<div #splashScreen class="splash-screen">
  <svg
    viewBox="0 0 16.968 18.858"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-left: 5px; margin-bottom: 10px; max-height: 40px !important"
  >
    <g fill="#f00">
      <path
        d="m11.269 6.123c-1.7 1.6-3.47 3.663-3.47 3.663l-3.815-3.253 3.525 7.1c1.864-4.413 9.459-12.133 9.459-12.133a70.613 70.613 0 0 0 -5.699 4.623z"
      />
      <path
        d="m13.206 12.153a13.872 13.872 0 0 1 -5.64 5.563 13.934 13.934 0 0 1 -5.644-5.563c-.789-1.642-1.083-4.606-.834-8.371l6.477-2.682 5.778 2.4.934-.718-6.715-2.782-7.431 3.074-.031.352c-.171 2.361-.279 6.691.917 9.174a15 15 0 0 0 6.326 6.154l.215.1.218-.1a14.859 14.859 0 0 0 6.33-6.154c1.039-2.158 1.094-5.715.979-8.157l-.964 1.234a15.51 15.51 0 0 1 -.915 6.476z"
      />
    </g>
  </svg>
  <mat-spinner diameter="40"></mat-spinner>
</div>
