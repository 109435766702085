import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TutorialOverlayService } from '../../tutorial-overlay/tutorial-overlay.service';
import checkUrl from './check-url';

/**
 * Interceptor for all HTTP requests if in tutorial mode to return mock data for the tutorial. If not in tutorial mode, request will continue as usual
 */
@Injectable()
export class TutorialModeInterceptor implements HttpInterceptor {
  constructor(private tutorialOverlayService: TutorialOverlayService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.tutorialOverlayService.inTutorialMode) {
      return checkUrl(req, next);
    }

    return next.handle(req);
  }
}
