// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '../../_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../app/core/_config/layout.config';
import { MenuConfig } from '../../../app/core/_config/menu.config';
import { PageConfig } from '../../../app/core/_config/page.config';
// User permissions
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { ActivatedRoute } from '@angular/router';
import { ReportGenerationService } from 'src/app/views/pages/vulnerabilities/services/report-generation.service';
import { TutorialOverlayService } from 'src/app/core/tutorial-overlay/tutorial-overlay.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeginTutorialModalComponent } from 'src/app/core/tutorial-overlay/begin-tutorial-modal/begin-tutorial-modal.component';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnInit, OnDestroy {
  // Public variables
  selfLayout = 'default';
  asideSelfDisplay: true;
  contentClasses = '';
  contentContainerClasses = '';
  subheaderDisplay = true;
  contentExtended: false;

  // Private properties
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * param layoutConfigService: LayoutConfigService
   * param menuConfigService: MenuConfigService
   * param pageConfigService: PageConfigService
   * param htmlClassService: HtmlClassService
   * param store
   * param permissionsService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private tutorialOverlayService: TutorialOverlayService,
    private modalService: NgbModal,
    public reportGenerationService: ReportGenerationService
  ) {
    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
      // reset body class based on global and page level layout config, refer to html-class.service.ts
      document.body.className = '';
      this.htmlClassService.setConfig(layoutConfig);
    });
    this.unsubscribe.push(subscription);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    // Load UI from Layout settings
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideSelfDisplay = objectPath.get(config, 'aside.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');
    this.contentClasses = this.htmlClassService.getClasses('content', true).toString();
    this.contentContainerClasses = this.htmlClassService.getClasses('content_container', true).toString();
    this.contentExtended = objectPath.get(config, 'content.extended');

    // let the layout type change
    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe((cfg) => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout');
      });
    });

    // check if the tutorial modal should be shown after 1.5 seconds. 1.5 second delay due to the loading splash screen being displayed on portal init
    setTimeout(() => {
      if (this.tutorialOverlayService.checkIfShowModal()) {
        this.modalService.open(BeginTutorialModalComponent, {
          size: 'lg',
          centered: true,
        });
      }
    }, 1500);

    this.unsubscribe.push(subscription);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
    // https://www.npmjs.com/package/ngx-permissions
    this.permissionsService.flushPermissions();
  }
}
