// Angular
import { Component } from '@angular/core';
// Layout
import { OffcanvasOptions } from '../../../_base/layout';
import { AuthService } from 'src/app/core/auth';
import { GlobalUtilService } from 'src/app/shared/services/shared-singleton/global-util.service';

@Component({
  selector: 'kt-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss'],
})
export class QuickUserPanelComponent {
  user$ = this.authService.getUserData();
  loggedInUser;
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'offcanvas',
    placement: 'right',
    closeBy: 'kt_quick_user_close',
    toggleBy: 'kt_quick_user_toggle',
  };

  constructor(private authService: AuthService, private globalUtilService: GlobalUtilService) {
    this.loggedInUser = JSON.parse(localStorage.getItem('userDetails'));
  }

  /**
   * Logs out the user
   */
  logout() {
    return this.authService.logout();
  }

  /**
   * Call the auth service to send a password reset email
   */
  resetPassword() {
    return this.authService.passwordReset().subscribe(
      (res) => {
        this.globalUtilService.openNotification('Password reset email requested', 'Success');
      },
      (err) => {
        console.error(err);
        this.globalUtilService.openNotification('Password reset email requested', 'Failed');
      }
    );
  }

  // /**
  //  * Opens the user manual in a new tab
  //  */
  // openUserManual() {
  //   window.open('/assets/media/documents/user-manual.pdf');
  // }
}
