export const assets = {
  next: 2,
  previous: null,
  count: 169,
  size: 10,
  current: 1,
  results: [
    {
      id: '23f4edef-3234-4ffe-bb00-e4ccdbc0de74',
      name: 'Example Host One',
      host: 'Host Example One',
      state: 3,
      description: '',
      vendor: '',
      model: '',
      version: 'testing a new asset',
      company: {},
      g_tags: [],
      j_tags: [],
      created_on: '2023-10-01',
      updated_on: '2023-10-01',
      customer_alert: null,
      internal_alert: null,
      vulns_impact: null,
      created: '2023-10-01T11:22',
      updated: '2023-10-01T11:08',
    },
    {
      id: '23f4edef-323e-4ffe-bbs0-e4ccdbc0de74',
      name: 'Example Host Two',
      host: 'www.host-two.com',
      state: 3,
      description: '',
      vendor: '',
      model: '',
      version: 'testing a new asset',
      company: {},
      g_tags: [],
      j_tags: [],
      created_on: '2023-10-01',
      updated_on: '2023-10-01',
      customer_alert: null,
      internal_alert: null,
      vulns_impact: null,
      created: '2023-10-01T11:22',
      updated: '2023-10-01T11:08',
    },
    {
      id: '23f4edef-323e-4f1e-bbs0-e4ccdbs0de74',
      name: 'Example Host Three',
      host: 'Host Three',
      state: 3,
      description: '',
      vendor: '',
      model: '',
      version: 'testing a new asset',
      company: {},
      g_tags: [],
      j_tags: [],
      created_on: '2023-10-01',
      updated_on: '2023-10-01',
      customer_alert: { alert: 'Warning, Will be made inactive in 5 days.' },
      internal_alert: null,
      vulns_impact: null,
      created: '2023-10-01T11:22',
      updated: '2023-10-01T11:08',
    },
  ],
};
