import { Injectable } from '@angular/core';
import { openUserNotifier } from '../store/filters.actions';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../../../core/reducers';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';
import { CheckedVulnerabilities } from '../models/checked-vulnerabilities.model';
import * as dayjs from 'dayjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/**
 * @ignore
 */
interface CreateReportPayload {
  customer: string;
  customer_id: string;
  data: {
    start_date: string;
    remediated: string[];
    existing: string[];
    scope: { name: string }[];
  };
}
/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class ReportGenerationService {
  /**
   * loading spinner behavior subject
   */
  isLoadingReport = new BehaviorSubject(false);
  /**
   * polling timeout variable
   */
  pollingTimeoutTime: number | null = null;
  /**
   * report title
   */
  readonly reportTitle = new BehaviorSubject<string>(null);

  /**
   * @ignore
   */
  constructor(private fileSaverService: FileSaverService, private store: Store<AppState>, private http: HttpClient) {}

  /**
   * stores the report name in the reportTitle BehaviorSubject
   *
   * @param title report name
   */
  setReportTitle(title: string) {
    this.reportTitle.next(title);
  }

  /**
   * returns the reportTitle value
   *
   * @returns string
   */
  getReportTitle(): string {
    return this.reportTitle.getValue();
  }

  /**
   * Prepare the payload for createReport. Seperate vulns into remediated or existing depending on their status value, get customer name and id, get start_date
   *
   * @param checkedVulns
   * @returns
   */
  buildPayload(checkedVulns: CheckedVulnerabilities): CreateReportPayload {
    const { name, id } = JSON.parse(localStorage.getItem('userDetails')).organisation;
    const payload = {
      customer: name,
      customer_id: id,
      data: {
        start_date: dayjs().format(),
        remediated: [],
        existing: [],
        scope: [],
      },
    };
    for (const vulnId in checkedVulns) {
      if (checkedVulns.hasOwnProperty(vulnId) && checkedVulns[vulnId].isChecked) {
        if (checkedVulns[vulnId].status === 'fixed') payload.data.remediated.push({ _id: vulnId });
        else payload.data.existing.push({ _id: vulnId });
      }
    }

    return payload;
  }

  /**
   * Fires a request to the report endpoint which will return a file_url that is used to poll the report.
   *
   * @param payload Report params
   * @returns an observable of a small report or the pollFileUrl method
   */
  createReport(checkedVulns: CheckedVulnerabilities): any {
    this.isLoadingReport.next(true);

    return this.http.post(`${environment.dirtyfixApi}/report`, this.buildPayload(checkedVulns)).pipe(
      switchMap((res: any) => this.pollFileUrl(res.file_url)),
      tap((blob: any) => {
        const fileName = `${this.getReportTitle() + '.docx'}`;
        this.fileSaverService.save(blob, fileName);
        this.isLoadingReport.next(false);
        return this.store.dispatch(
          openUserNotifier({
            messageUser: {
              message: `Report generated - ${fileName}`,
              type: 'Success',
            },
          })
        );
      }),
      catchError((err) => {
        this.isLoadingReport.next(false);
        return this.handleError(err);
      })
    );
  }

  /**
   * A recursive method to constantly poll the file_url provided by the report endpoint received in getReport
   * If the report is not ready, a 400 error status will respond. This will call pollFileUrl again to repat the process
   * The process is repeated until the report is completed, the 20 minute timeout is reached, or there is an error that doesn't have the status code 400
   *
   * @param url the url provided by the /report endpoint
   * @returns The large report
   */

  pollFileUrl(url: string): Observable<any> {
    return timer(3000).pipe(
      switchMap(() => {
        if (!this.handleTimeout()) return this.http.get(url, { responseType: 'blob' });
        else return throwError({ pollTimeout: 'Building report exceeded 30 minute timeout - Build Aborted' });
      }),
      switchMap((res: any) => {
        this.pollingTimeoutTime = null;
        return of(res);
      }),
      catchError((err) => {
        if (err.status === 400 || err.status === 404) return this.pollFileUrl(url);
        this.pollingTimeoutTime = null;
        return throwError(err);
      })
    );
  }

  /**
   * Handle a timeout on polling the file url
   *
   * @returns boolean indicating if the polling should be terminated
   */
  handleTimeout(): boolean {
    // set the initial timeout time to 30 minutes if first poll
    if (!this.pollingTimeoutTime) {
      this.pollingTimeoutTime = new Date().getTime() + 1800000;
      return false;
    }
    // reset the timeout count to null if the time has been reached and return true
    if (this.pollingTimeoutTime <= new Date().getTime()) {
      this.pollingTimeoutTime = null;
      return true;
    }
    return false;
  }

  /**
   * Handles any errors that occurs whole report generation
   *
   * @param err the error that occured
   * @returns void
   */
  handleError(err) {
    console.error(err);
    this.store.dispatch(
      openUserNotifier({ messageUser: { message: `Network error: ${err.statusText}`, type: 'Warning' } })
    );
    return throwError('error');
  }
}
