export const notifications = {
  next: false,
  previous: null,
  count: 1,
  size: 10,
  current: 1,
  results: [
    {
      id: '44a6caf4-e793-4f26-b9af-6bb949133eea',
      is_read: true,
      category: 'Asset',
      subject: 'Example Notification',
      message: 'Asset Example One has been declined.',
      consumer_id: ['e240ed1f-cd21-4061-a1d0-5885d1d5dd64', '502bb74e-1ec8-4903-baec-49f48dfe4f3f'],
      is_important: false,
      created: '2023-09-11T11:11',
      updated: '2023-10-11T10:25',
      deleted: false,
    },
  ],
};
