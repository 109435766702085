import { TutorialStepConfig } from '../types/tutorial-step-config';
import { clickElement } from './helpers';

export const reportStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Reports Page',
    text:
      'The reports page shows the reports uploaded by our security team. Here you can download a single report or multiple ones at once.',
  },
  {
    title: 'Reports Table',
    text: 'The reports table shows you a list of downloadble reports and the date they were uploaded and updated.',
    attachTo: {
      element: '#tutorial-reports-table',
      on: 'bottom',
    },
  },
  {
    title: 'Download a Single Report',
    text: 'Each report has a download button located in the last column of the table.',
    attachTo: {
      element: '#tutorial-reports-single-download',
      on: 'left',
    },
  },
  {
    title: 'Download Multiple Reports - Selecting',
    text: 'The reports you wish to download together are selected by ticking the checkbox.',
    attachTo: {
      element: '#tutorial-reports-checkbox',
      on: 'right',
    },
    extraAction: () => {
      clickElement('tutorial-reports-checkbox');
    },
  },
  {
    title: 'Download Multiple Reports - Downloading',
    text: 'Clicking the Download button will begin multiple downloads for the reports you selected',
    attachTo: {
      element: '#tutorial-reports-download-button',
      on: 'left',
    },
  },
];
